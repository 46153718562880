import {useState, useEffect} from 'react'
import PageTitle from '../components/Typography/PageTitle'
import CasesTable from '../components/Tables/CasesTable'
import NewCaseButton from '../components/Buttons/NewCaseButton';
import toast from 'react-hot-toast';
import axios from 'axios';
import Gear from '../components/Buttons/Gear';





function Cases() {
    
    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const processUrl = `${BACKEND_URL}/v1/ops/process`;

    const [processes, setProcesses] = useState([])
    const [proc, setProc] = useState([])

    let prcs = []
    let old = []
    
    const getProcesses = async () => {
        await axios.get(`${processUrl}?type=case.generate`)
            .then((r) => {
                old = processes
                prcs = []
                var results = r.data.results

                for (var i in results){
                    if (!results[i]['success']){
                        prcs = [...prcs, results[i]]
                    }
                    else if (results[i]['success']){
                        for (var n in old){
                            if (results[i]['id'] === old[n]['id']){
                                toast.success('Generation Complete!')
                                setProc(old[n])
                            }
                        }
                    }
                }
                setProcesses(prcs)
            }).catch((e) => {
                console.log(e)
            })
    }


    useEffect(() => {
        const interval = setInterval(() => {
            console.log('triggered interval')
            getProcesses();
        }, 5000);
        return () => {
          clearInterval(interval);
        };
        // eslint-disable-next-line
      }, [processes]);


    
    useEffect(() => {
        getProcesses();
    }, [])
    
    

    return (
        <>
        <div className='flex justify-between mb-6'>
            <div>
                <PageTitle>Cases</PageTitle>
                <p className='text-gray-600 dark:text-gray-400 max-w-sm'>
                    Where all your functional tests live. Record your own using our free <a className='underline decoration-blue-600' href='https://chromewebstore.google.com/detail/scanerr/bajdjmldkolkpebdfffdnekmlpnhadok' target='_blank'>chrome extension</a>, 
                    or our new A.I. powered Case Generator.
                </p>
            </div>
            
            
            <div className='mt-8'>
                <NewCaseButton callBack={getProcesses}/>
            </div>

        </div>
       
       {/* process display */}
       {processes && processes.length >= 1 &&
            <div className='my-4 rounded-lg bg-white dark:bg-gray-800 max-w-3xl py-1 px-8 max-h-[10rem] overflow-scroll'>
                {processes.map((process, i) => (
                    <div key={i} className='my-6'>
                        <div className='flex justify-start gap-6'>
                            <div className='flex justify-start gap-2'><Gear size='h-5 w-5'/> <p className='text-gray-600 dark:text-gray-400 text-sm pb-2'>Generating Cases</p></div>
                            <div><pre className='text-gray-600 dark:text-gray-400 text-[10px] pt-[.15rem]'><code>process_id: {process.id}</code></pre></div>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div className="bg-blue-600 h-2.5 rounded-full transition-all ease-out duration-1000" style={{width: `${process.progress}%`}}></div>
                        </div>
                    </div>
                ))}
            </div>
       }
        


        <div className='mt-6'>
            <CasesTable proc={proc}/>
        </div>    


        </>
    )
}

export default Cases