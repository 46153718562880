import { useEffect, useState } from 'react'
import { Badge } from '@windmill/react-ui';
import Gear from '../Buttons/Gear';
import $ from 'jquery'




function StepsCard (props) {

    const [steps, setSteps] = useState(props.testcase.steps)
    const [selected, setSelected] = useState(0)

    useEffect(() => {
      setSteps(props.testcase.steps)
      // eslint-disable-next-line
    }, [props?.testcase])

    function showDetails(id) {
        if($("#step-detail-"+id).is(":visible")){
            $("#step-detail-"+id).hide()
        }else{
            $("#step-detail-"+id).show()
        }
        setSelected(id)
      }
    
    const getColor = (step) => {
        var color = '#94a3b8'
        if(step.action.time_created !== null){
            color = '#1c64f2'
        }
        if (step.action.passed === false || step.assertion.passed === false){
            color = '#B43A29'
        }
        if (step.action.passed === true && step.assertion.passed !== false){
            color = '#38B43F' 
        }
        
        return color
    }


    const getStepStatus = (step, i) => {
        var status = 'queued'
        var type = 'neutral'

        if(step.action.time_created !== null){
            status = 'working'
            type = 'primary'
        }
        if (step.action.passed === false || step.assertion.passed === false){
            status = 'failed'
            type = 'danger'
        }
        if (step.action.passed === true && step.assertion.passed !== false){
            status = 'passed'
            type = 'success'
        }

        return <Badge type={type}>{status} {status === 'working' && <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/>}</Badge>
      }


    return (
        // style={{ maxHeight: '26rem' }} max-h-[26rem]  -- max-h-[80vh]

        <div className='overflow-auto min-w-0 rounded-lg shadow-xs bg-white dark:bg-gray-800 p-4'> 
            <h1 className='text-lg pl-4 pt-4 font-bold dark:text-gray-300'>Steps</h1>
                {/* <div className='overflow-auto pb-8 h-full'>  */}
                    {steps &&
                        steps.map((step, i) => (
                            
                            <div 
                                key={i}
                                className={`rounded-r-md my-4 flex justify-start hover:-translate-y-1 ease-in-out duration-150`} 
                            > 
                                <div className='h-auto w-2 rounded-l-md' style={{backgroundColor: getColor(step) }}></div>
                                <div className={`rounded-r-md px-2 py-2 bg-gray-100 dark:bg-gray-700 w-full ${selected === i && 'border-[1px] border-l-[0px] dark:border-gray-300 border-gray-500'}`} >
                                    <div 
                                        className='flex justify-between cursor-pointer px-6'
                                        onClick={() => {
                                            showDetails(i)
                                            props.callBack(step, i)
                                        }}
                                    >
                                        <h3 className='mt-2 font-semibold text-sm mb-2 dark:text-gray-300 my-auto'>
                                            Step #{i+1}
                                        </h3>
                                        <h3 className='mt-2 font-italic text-sm mb-2 dark:text-gray-300 my-auto'>
                                            {`"${step.action.type}"`}
                                        </h3>
                                        <div className='mt-2'>
                                            {getStepStatus(step, i)}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        ))

                    }

                {/* </div> */}
        </div>
    )
}


export default StepsCard