import React, { useState, useEffect } from 'react';
import { Input, Button,Label, Select, 
  Textarea } from '@windmill/react-ui';
import { useSelector } from 'react-redux'
import Timezones from './Timezones';
import Times from './Times';
import axios from "axios";
import toast from 'react-hot-toast';
import { ClockIcon } from '../../icons';
import { XIcon } from '@heroicons/react/outline';
import { CodeIcon, ExclamationIcon, 
  PhotographIcon, PresentationChartBarIcon, 
  PresentationChartLineIcon, CheckIcon, 
  ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import Datepicker from "tailwind-datepicker-react"
import Spinner from '../Buttons/Spinner'
import Toggle from '../Toggle/Toggle';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'






function ScheduleModal(props) {

  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/schedule'
  const caseSearchUrl = BACKEND_URL + '/v1/ops/case/search?query='
  const searchUrl = BACKEND_URL + '/v1/ops/search?query='
  const caseUrl = BACKEND_URL + '/v1/ops/case'
  const scope = props.scope
  const schedule_id = props.schedule ? props.schedule.id : '';
  const teamConfigs = useSelector(state => state.team.team.configs)


  const [begin_date, setBeginDate] = useState(props.schedule ? new Date(props.schedule.begin_date) : new Date());
  const [time, setTime] = useState(props.schedule ? props.schedule.time : '00:00');
  const [timezone, setTimezone] = useState(props.schedule ? props.schedule.timezone : 'UTC');
  const [freq, setFreq] = useState(props.schedule ? props.schedule.frequency : 'daily');
  const [task_type, setTaskType] = useState(props.schedule ? props.schedule.task_type : '');
  const [window_size, setWindowSize] = useState();
  const [browser, setBrowser] = useState()
  const [device, setDevice] = useState()
  const [mask_ids, setMaskIds] = useState()
  const [interval, setInterval] = useState()
  const [timeout, setTimeout] = useState()
  const [min_wait_time, setMinTime] = useState()
  const [max_wait_time, setMaxTime] = useState()
  const [disableAnimations, setDisableAnimation] = useState()
  const [autoHeight, setAutoHeight] = useState()
  const [createIssue, setCreateIssue] = useState()
  const [threshold, setThreshold] = useState(props.schedule ? props.schedule.extras.threshold : 95)
  const [test_type, setTestType] = useState(props.schedule ? props.schedule.extras.test_type : ['html', 'logs', 'lighthouse', 'yellowlab', 'vrt']);
  const [scan_type, setScanType] = useState(props.schedule ? props.schedule.extras.scan_type : ['html', 'logs', 'lighthouse', 'yellowlab', 'vrt']);
  const [cas_id, setCasId] = useState(props.schedule ? props.schedule.extras.case_id : null);
  const [updates, setUpdates] = useState(props.schedule ? props.schedule.extras.updates ? props.schedule.extras.updates : null : null)
  const [isTest, setIsTest] = useState(props.schedule ? props.schedule.task_type === 'test' ? 'block': 'none' : 'none')
  const [isScan, setIsScan] = useState(props.schedule ? props.schedule.task_type === 'scan' ? 'block': 'none' : 'none')
  const [showConfigs, setShowConfigs] = useState(props.schedule ? (props.schedule.task_type === 'report') ? 'none' : 'block' : 'none')
  const [resources, setResources] = useState(props.schedule ? props.schedule.resources : (props.scope === 'account' ? [] : [{'type': props.scope, 'id': props.object.id, 'str': props.object[`${props.scope}_url`]}]))
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(false)
  const [results, setResults] = useState(null)
  const [caseQuery, setCaseQuery] = useState('')
  const [caseOpen, setCaseOpen] = useState(false)
  const [cases, setCases] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showResources, setShowResources] = useState(props.schedule ? props.schedule.resources.length > 0 : true);
  const [showUpdates, setShowUpdates] = useState(true);


  useEffect(() => {
    setWindowSize(props.schedule ? props.schedule.extras.configs.window_size : (teamConfigs ? teamConfigs.window_size : '1920,1080'))
    setBrowser(props.schedule ? props.schedule.extras.configs.browser : (teamConfigs ? (teamConfigs.browser !== undefined ? teamConfigs.browser : 'chrome') : 'chrome'))
    setDevice(props.schedule ? props.schedule.extras.configs.device : (teamConfigs ? teamConfigs.device : 'desktop'))
    setMaskIds(props.schedule ? props.schedule.extras.configs.mask_ids : (teamConfigs ? teamConfigs.mask_ids : ''))
    setInterval(props.schedule ? props.schedule.extras.configs.interval : (teamConfigs ? teamConfigs.interval : 1))
    setTimeout(props.schedule ? props.schedule.extras.configs.timeout : (teamConfigs ? teamConfigs.timeout : 300))
    setMinTime(props.schedule ? props.schedule.extras.configs.min_wait_time : (teamConfigs ? teamConfigs.min_wait_time : 3))
    setMaxTime(props.schedule ? props.schedule.extras.configs.max_wait_time : (teamConfigs ? teamConfigs.max_wait_time : 60))
    setDisableAnimation(props.schedule ? props.schedule.extras.configs.disable_animations : (teamConfigs ? teamConfigs.disable_animations : false))
    setAutoHeight(props.schedule ? props.schedule.extras.configs.auto_height : (teamConfigs ? teamConfigs.auto_height : true))
    setCreateIssue(props.schedule ? props.schedule.extras.configs.create_issue : (teamConfigs ? teamConfigs.create_issue : true))
  }, [])


  useEffect(() => {
    document.addEventListener('click', handleModalClose, { capture: true })
    return () => {
      document.removeEventListener('click', handleModalClose)
    }
  })

  const handleModalClose = (e) => {
    try{
      var modalElement = document.querySelector('#schedule-modal');
      if (!modalElement.includes(e.target)) {
        console.log('click was not in modal')
        // console.log(e)
        closeModal()
      }
    } catch {}
  }


  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false)
    resetData();
  }


  useEffect(() => {
    const loadData = () => {
      if (props.schedule){
        if (props.schedule.extras.case_id){
          axios.get(`${caseUrl}/${props.schedule.extras.case_id}`)
            .then((r) => {
              setCaseQuery(r.data.name)
            })
            .catch((e) => {
              console.log(e)
            })
        }
      }
      
    }
    loadData()
    // eslint-disable-next-line
  }, [])



  const resetData = () => {
    setBeginDate(new Date())
    setTime('00:00')
    setTimezone('UTC')
    setFreq('daily')
    setTaskType('')
    setWindowSize('1920,1080')
    setDevice('desktop')
    setMaskIds('')
    setInterval(5)
    setMinTime(10)
    setMaxTime(60)
    setTimeout(300)
    setDisableAnimation(false)
    setTestType(['html', 'logs', 'lighthouse', 'yellowlab', 'vrt'])
    setScanType(['html', 'logs', 'lighthouse', 'yellowlab', 'vrt'])
    setCasId(null)
    setCases(null)
    setCaseOpen(false)
    setCaseQuery('')
    setUpdates(null)
    setOpen(false)
    setQuery('')
    setResults(null)
    setShowConfigs('none')
    setIsTest('none')
    setIsScan('none')
    setIsLoading(false)
    setResources([])
    setShowResources(true)
  }


  const searchCases = async () => {
    if (caseQuery !== '') {
      await axios.get(`${caseSearchUrl}${caseQuery}`)
        .then((r) => {
            setCases(r.data)
        })
        .catch((e) => {
            console.log(e)
        })
    } else {
      // console.log(query)
    }
  }


  const search = async () => {
    if (query !== ''){
      await axios.get(`${searchUrl}${query}`)
        .then((r) => {
            setResults(r.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }
  }


  const checkResources = (item) => {
    for (let i in resources) {
      if (resources[i]['id'] === item['id']){
        return true
      }
    }
    return false
  }


  const addUpdates = (cas) => {
    var newUpdates = []
    fetch(cas.steps.url)
        .then(r => r.json())
        .then((out) => {
          var steps = out
          for (var i in steps) {
            var step = steps[i]
            if (
              step.action.value !== '' && 
              step.action.value !== null &&
              step.action.type == 'change'
            ){
              var update = {
                value: step.action.value,
                index: i
              }
              newUpdates.push(update)
            }
          }
          setUpdates(newUpdates)
        }).catch((e)=>{
          console.log(e)
        })
  }


  const updateChange = (e, i) => {
    const { value } = e.target;
    const list = [...updates];
    list[i]['value'] = value;
    setUpdates(list);
  }
  


  const [testTypes, setTestTypes] = useState([
    {
      value: "html", 
      name: "HTML Page Source",
      checked: test_type.includes('html'),
    },
    {
      value: "logs", 
      name: "Console Logs",
      checked: test_type.includes('logs'),
    },
    {
      value: "lighthouse", 
      name: "Lighthouse Metrics",
      checked: test_type.includes('lighthouse'),
    },
    {
      value: "yellowlab", 
      name: "YellowLab Metrics",
      checked: test_type.includes('yellowlab'),
    },
    {
      value: "vrt", 
      name: "Visual Regression",
      checked: test_type.includes('vrt'),
    },
  ])


  const handleTestType = (value, i) => {
    if (test_type.includes(value)) {
      var list = [...test_type];
      setTestType(list.filter(function(item) { 
          return item !== value 
      }))
      const types_list = [...testTypes]
      types_list[i]['checked'] = false;
      setTestTypes(types_list)

    } else {
      setTestType([...test_type, value])
      const types_list = [...testTypes]
      types_list[i]['checked'] = true;
      setTestTypes(types_list)
    }

  }


  const getIcon = (value) => {
    let icon = null
    switch(value) {
      case 'html':
        icon = <CodeIcon className='h-6 w-8'/>
        break;
      case 'logs':
        icon = <ExclamationIcon className='h-6 w-6'/>
        break;
      case 'lighthouse':
        icon = <PresentationChartBarIcon className='h-6 w-6'/>
        break;
      case 'yellowlab':
        icon = <PresentationChartLineIcon className='h-6 w-6'/>
        break;
      case 'vrt':
        icon = <PhotographIcon className='h-6 w-6'/>
        break;
    }
    return icon
  }


  const [scanTypes, setScanTypes] = useState([
    {
      value: "html", 
      name: "HTML Page Source",
      checked: scan_type.includes('html'),
    },
    {
      value: "logs", 
      name: "Console Logs",
      checked: scan_type.includes('logs'),
    },
    {
      value: "lighthouse", 
      name: "Lighthouse Metrics",
      checked: scan_type.includes('lighthouse'),
    },
    {
      value: "yellowlab", 
      name: "YellowLab Metrics",
      checked: scan_type.includes('yellowlab'),
    },
    {
      value: "vrt", 
      name: "Visual Regression",
      checked: scan_type.includes('vrt'),
    },
  ])


  const handleScanType = (value, i) => {

    if (scan_type.includes(value)) {
      var list = [...scan_type];
      setScanType(list.filter(function(item) { 
          return item !== value 
      }))
      const types_list = [...scanTypes]
      types_list[i]['checked'] = false;
      setScanTypes(types_list)

    } else {
      setScanType([...scan_type, value])
      const types_list = [...scanTypes]
      types_list[i]['checked'] = true;
      setScanTypes(types_list)
    }

  }



  const handleAddSchedule = async (id) => {

    const configs = {
      "interval": interval,
      "window_size": window_size,
      "browser": browser,
      "device": device,
      "mask_ids": mask_ids,
      "min_wait_time": min_wait_time,
      "max_wait_time": max_wait_time,
      "timeout": timeout,
      "disable_animations": disableAnimations,
      "auto_height": autoHeight,
      "create_issue": createIssue,
    }
    const data = {
      "site_id": props.site ? props.site.id : (props.siteIds ? id : null),
      "page_id": props.page ? props.page.id : (props.pageIds ? id : null),
      "scope": scope,
      "resources": resources,
      "begin_date": begin_date,
      "time": time, 
      "timezone": timezone,
      "frequency": freq,
      "task_type": task_type,
      "schedule_id": schedule_id,
      "test_type": test_type,
      "scan_type": scan_type,
      "case_id": cas_id,
      "updates": updates,
      "configs": configs,
      "threshold": threshold,
    }

    if(scope !== 'account' && resources.length === 0){
      toast.error('must specify a resource')
      return
    }

    setIsLoading(true);

    await axios.post(`${url}`, data)
    .then((r) => {
      setIsLoading(false);
      setIsModalOpen(false);
      resetData();
      props.callBack && props.callBack();
      // running success toast only if single schedule
      toast.success(props.schedule ? 'Schedule updated!' : 'Schedule created!')
    })
    .catch((e) => {
      console.log(e)
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    })
  }




  const dateOptions = {
    title: "",
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    clearBtnText: "Clear",
    // maxDate: new Date("2030-01-01"),
    // minDate: new Date("1950-01-01"),
    theme: {
      background: "bg-white dark:bg-gray-700",
      todayBtn: "text-xs",
      clearBtn: "",
      icons: "",
      text: "text-xs",
      disabledText: "bg-gray-100 dark:bg-gray-600",
      input: "py-2 bg-white dark:bg-gray-700 border-gray-600 border-w-[1px]",
      inputIcon: "",
      selected: "",
    },
    icons: {
      // () => ReactElement | JSX.Element
      prev: () => <span><ArrowLeftIcon className='w-3'/></span>,
      next: () => <span><ArrowRightIcon className='w-3'/></span>,
    },
    datepickerClassNames: "top-[20%]",
    defaultDate: new Date(),
    language: "en",
    disabledDates: [],
    weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Select Date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric"
    }
  }



  const ButtonType = () => {
    if(props.type==='icon'){
      return(
          <Button onClick={openModal} layout="link" size="icon" aria-label="New Schedule">
            <ClockIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
      )
    }else if(props.type==='button'){
      return (
        <div>
          <Button className='w-[9rem]' onClick={openModal} iconRight={ClockIcon} layout='outline'>
            Schedule
          </Button>
        </div>
      )
    }else if(props.type==='account-button'){
      return (
        <div>
          <Button className='' onClick={openModal} layout='outline' >
            New Schedule
          </Button>
        </div>
      )
    }else if(props.type==='text'){
      return (
        <span onClick={openModal} className="cursor-pointer capitalize font-semibold">
          { props.text ? props.text : 'New Schedule' }
        </span>
      )
    }else if(props.type==='dropdown'){
      return (
        <DropdownItem 
          onClick={(e) => {
            openModal()
            }} 
          className="z-40 relative">
          <ClockIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          <span>Schedule</span>
        </DropdownItem>
      )
    }
  }




  return (
    <>

      <div>{ButtonType()}</div>

      <Modal 
        isOpen={isModalOpen} 
        onClose={closeModal}
        style={{ maxHeight: '100vh', position: 'absolute', overflow: 'scroll', display:'block'}}
        title={props.schedule ? 'Update Schedule' : 'New Schedule'}
      >
        <div id='schedule-modal'>
          <ModalBody onClick={e=>e.nativeEvent.stopImmediatePropagation()}>
          <div className='overflow-auto px-3' style={{ maxHeight: '32rem' }}>
          
            <div className=''>
              <Label className="mb-2">Task Type:</Label>
              <Label radio>
                <Input 
                  type="radio" 
                  value="scan" 
                  checked={task_type === 'scan' ? true : false} 
                  onChange={(e) => {
                    setTaskType(e.target.value)
                    setShowConfigs('block')
                    setIsTest('none')
                    setIsScan('block')
                  }}
                />
                <span className="ml-2">Scan</span>
              </Label>
              <Label className="ml-6" radio >
                <Input 
                  type="radio" 
                  value="test" 
                  checked={task_type === 'test' ? true : false} 
                  onChange={(e) => {
                    setTaskType(e.target.value)
                    setShowConfigs('block')
                    setIsTest('block')
                    setIsScan('none')
                  }}
                />
                <span className="ml-2">Test</span>
              </Label>
              {(props.scope === 'account' || props.scope === 'site') &&
                <Label className="ml-6" radio >
                  <Input 
                    type="radio" 
                    value="testcase" 
                    checked={task_type === 'testcase' ? true : false} 
                    onChange={(e) => {
                      setTaskType(e.target.value)
                      setShowConfigs('block')
                      setIsTest('none')
                      setIsScan('none')
                    }}
                  />
                  <span className="ml-2">Testcase</span>
                </Label>
              }
              <Label className="ml-6" radio >
                <Input 
                  type="radio" 
                  value="report" 
                  checked={task_type === 'report' ? true : false} 
                  onChange={(e) => {
                    setTaskType(e.target.value)
                    setShowConfigs('none')
                    setIsTest('none')
                    setIsScan('none')
                  }}
                />
                <span className="ml-2">Report</span>
              </Label>
              
              <div style={{ display: (scope === 'account' && task_type) ? 'block' : 'none' }} >
                <div className='relative mb-8 mt-6' >
                  <label className='mb-3'>
                    <span className='text-sm text-gray-700 dark:text-gray-400 mr-1'>Apply to:</span>
                    
                    <span 
                      className={`text-xs ml-2 mb-1 cursor-pointer rounded-md px-1 py-[.15rem] border-[1px] dark:text-gray-300 dark:border-gray-400 text-gray-600 border-gray-600 ${showResources && 'bg-blue-100 dark:bg-blue-600'}`}
                      onClick={() => setShowResources(true)}
                    >
                      Custom
                    </span>
                    <span 
                      className={`text-xs ml-2 mb-1 cursor-pointer rounded-md px-1 py-[.15rem] border-[1px] dark:text-gray-300 dark:border-gray-400 text-gray-600 border-gray-600 ${!showResources && 'bg-blue-100 dark:bg-blue-600'}`}
                      onClick={() => {
                        setShowResources(false)
                        setResources([])
                      }}
                    >
                      All Sites
                    </span>
                              
                    {showResources &&
                      <div resizeable='true' className='mt-3 resize-y min-h-12 h-auto overflow-y-auto flex flex-wrap dark:text-gray-300 form-input focus:border-blue-400 dark:border-gray-600 focus:shadow-outline-blue dark:focus:border-gray-600 dark:focus:shadow-outline-gray dark:bg-gray-700 rounded-md p-2 w-full'>
                      
                        {resources.map((item, i) => (
                          
                          <div key={i} className='m-1'>
                            <div className='flex justify-between px-2 py-2 dark:bg-gray-800 bg-gray-100 rounded-xl my-auto'>
                              <div className='mr-2 my-auto'>
                                <div className="flex justify-start cursor-auto">   
                                  <div className='mr-3 my-auto'>
                                    <div className={`text-center px-2 py-0 rounded-full text-[8px] ${item.type === 'site' ? 'dark:bg-blue-600 bg-blue-100' : 'bg-gray-200 dark:bg-gray-600'}`}>
                                      <span className={`font-bold py-0 ${item.type === 'site' ? 'dark:text-white text-blue-700' : 'dark:text-white text-gray-700'} `}>
                                        {item.type.toUpperCase()}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='my-auto'>
                                    <h2 className="font-semibold text-xs text-gray-700 dark:text-gray-300 max-w-[12rem] truncate my-auto">
                                      {item.str}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                              <div className='my-auto'>
                                <div 
                                  className='cursor-pointer text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-6 h-6 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white my-auto'
                                  onClick={() => {
                                    let _resources = [...resources];
                                    _resources.splice(i, 1);
                                    setResources(_resources);
                                  }} 
                                >
                                  <XIcon className="w-3 h-3" aria-hidden="true" />
                                </div>
                              </div>
                            </div>
                          </div>

                        ))}

                        <div className={`my-auto ${resources.length > 0 && 'mx-2'}`}>
                          <input
                            className={`my-auto text-sm outline-0 active:outline-0 active:border-0 focus:outline-0 focus:ring-0 focus:border-none border-none dark:text-gray-300 text-gray-600 leading-5 dark:border-gray-600 dark:bg-gray-700 rounded-md p-1 w-auto`}
                            placeholder='add a site or page'
                            value={query}
                            onChange={(e) => {
                                setQuery(e.target.value === '/' ? '' : e.target.value)
                                if (e.target.value !== '') {
                                  setOpen(true)
                                  search();
                                } else {
                                  setOpen(false)
                                }
                            }}
                          />
                        </div>
                      </div>
                    }
                  </label>
                  
                  
                  {query.length >= 1 && open && 
                    <div className={`w-full absolute z-30 mx-auto shadow rounded-b-lg p-4 bg-white dark:bg-gray-700 dark:text-gray-300 text-gray-600 overflow-x-scroll`}>

                        { results && results.map((item, i) => (
                          <div key={i}>
                            {(item.type === 'site' || (task_type !== 'testcase' && item.type === 'page')) && !checkResources(item) && 
                              <div key={i}>
                                <div 
                                  className="flex justify-start px-2 py-2 dark:hover:bg-gray-600 hover:bg-gray-100 my-2 rounded-md cursor-pointer" 
                                  onClick={() => {
                                    setResources([...resources, {
                                      'str': item.str,
                                      'id': item.id,
                                      'type': item.type
                                    }])
                                    setOpen(false)
                                    setQuery('')
                                  }}
                                >   
                                  <div className='mr-3 my-auto'>
                                    <div className={`text-center px-2 py-1 rounded-full text-[8px] ${item.type === 'site' ? 'dark:bg-blue-600 bg-blue-100' : 'bg-gray-200 dark:bg-gray-600'}`}>
                                      <span className={`font-bold ${item.type === 'site' ? 'dark:text-white text-blue-700' : 'dark:text-white text-gray-700'} `}>
                                          {item.type.toUpperCase()}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <h2 className="font-semibold text-sm">
                                        {item.str}
                                    </h2>
                                  </div>
                                </div> 
                              </div>
                            }
                          </div>
                        ))}

                    </div>
                  }
                </div>
              </div>
              
              <div>
                <div className="mt-6" style={{ display: isTest }}>
                    <Label className="text-sm">Test Type:</Label>
                    <div className='flex justify-between'>
                      {testTypes.map((type, i) => (
                        <div key={i}>
                          { test_type.includes(type.value) &&
                            <div className={`rounded-full bg-green-300 text-green-700 p-1 relative top-6 w-5 h-5 -mt-5`}>
                              <CheckIcon className='h-3 w-3'/>
                            </div>
                          }
                          <div 
                            onClick={() => handleTestType(type.value, i)}
                            className={`cursor-pointer mt-2 h-24 w-20 rounded-lg border-2 mx-auto text-center text-xs flex flex-col justify-evenly ${test_type.includes(type.value) ? 'text-gray-600 dark:text-gray-300 border-blue-600' : 'text-gray-600 dark:text-gray-400 border-gray-600 dark:border-gray-400'}`}
                          >
                            <div className='mx-auto'>{getIcon(type.value, i)}</div>
                            <span>{type.name}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                </div>

                <div className="mt-6" style={{ display: isScan }}>
                    <Label className="text-sm">Scan Type:</Label>
                    <div className='flex justify-between'>
                      {scanTypes.map((type, i) => (
                        <div key={i}>
                          { scan_type.includes(type.value) &&
                            <div className={`rounded-full bg-green-300 text-green-700 p-1 relative top-6 w-5 h-5 -mt-5`}>
                              <CheckIcon className='h-3 w-3'/>
                            </div>
                          }
                          <div
                            onClick={() => handleScanType(type.value, i)}
                            className={`cursor-pointer mt-2 h-24 w-20 rounded-lg border-2 mx-auto text-center text-xs flex flex-col justify-evenly ${scan_type.includes(type.value) ? 'text-gray-600 dark:text-gray-300 border-blue-600' : 'text-gray-600 dark:text-gray-400 border-gray-600 dark:border-gray-400'}`}
                          >
                            <div className='mx-auto'>{getIcon(type.value)}</div>
                            <span>{type.name}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                </div>
                
                <div style={{ display: task_type === 'testcase' ? 'block' : 'none' }} >
                  <div className='relative mb-8 mt-6' >
                    
                    {!cas_id && 
                      <Label className="mb-1 w-full">
                        <span className='mb-2'>Case</span>
                        <input 
                          className='block text-sm focus:outline-none dark:text-gray-300 form-input leading-5 focus:border-blue-400 dark:border-gray-600 focus:shadow-outline-blue dark:focus:border-gray-600 dark:focus:shadow-outline-gray dark:bg-gray-700 rounded-md p-2 w-full'
                          placeholder='search for a case'
                          value={caseQuery}
                          onChange={(e) => {
                              setCaseQuery(e.target.value === '/' ? '' : e.target.value)
                              if (e.target.value !== '') {
                                  setCaseOpen(true)
                                  setCaseQuery(e.target.value)
                                  searchCases(e.target.value)
                              } else {
                                  setCaseOpen(false)
                              }
                          }}
                        />
                      </Label>
                    }

                    {caseQuery.length >= 1 && caseOpen && !cas_id &&
                      <div className={`w-full absolute z-30 mx-auto shadow rounded-b-lg p-4 bg-white dark:bg-gray-700 dark:text-gray-300 text-gray-600 overflow-x-scroll`}>

                          { cases && cases.results.slice().map((cas, i) => (
                              <div key={i}>
                                  <div 
                                      className="flex justify-start px-2 py-2 dark:hover:bg-gray-600 hover:bg-gray-100 my-2 rounded-md cursor-pointer" 
                                      onClick={() => {
                                        setCaseQuery(cas.name)
                                        setCaseOpen(false)
                                        setCasId(cas.id)
                                        addUpdates(cas)
                                      }}
                                  >   
                                      <div className='mr-3 my-auto'>
                                          <div className={`text-center px-2 py-1 rounded-full text-[8px] bg-gray-300 dark:bg-gray-500`}>
                                              <span className={`font-bold dark:text-white text-gray-700`}>
                                                  CASE
                                              </span>
                                          </div>
                                      </div>
                                      <div>
                                          <h2 className="font-semibold text-sm">
                                              {cas.name}
                                          </h2>
                                      </div>
                                  </div> 
                              </div>
                          ))}

                      </div>
                    }

                  </div>


                  {cas_id && !open &&

                    <label className='mb-3'>
                      <span className='text-sm text-gray-700 dark:text-gray-400 '>Case:</span>
                      <div className='flex justify-between px-2 py-2 dark:bg-gray-700 bg-white rounded-xl my-auto ring-1 dark:ring-0'>
                        <div className='mr-2 my-auto'>
                          <div className="flex justify-start  cursor-auto">   
                            <div className='mr-3 my-auto'>
                              <div className={`text-center px-2 py-1 rounded-full text-[8px] bg-gray-300 dark:bg-gray-500`}>
                                <span className={`font-bold dark:text-white text-gray-700 `}>
                                    CASE
                                </span>
                              </div>
                            </div>
                            <div>
                              <h2 className="font-semibold text-sm text-gray-700 dark:text-gray-300 max-w-[12rem] truncate">
                                {caseQuery}
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className=''>
                          <div 
                            onClick={() => {
                              setCasId(null)
                              setUpdates(null)
                              setCaseQuery('')
                            }} 
                            className='cursor-pointer text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-6 h-6 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white my-auto'
                          >
                            <XIcon className="w-3 h-3" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                    </label>

                  }
                      


                  <div className='md:w-[80%] mt-6'>
                    {updates && (
                      updates.length !== 0 &&
                        <span 
                          className="text-sm cursor-pointer underline decoration-blue-600 text-blue-600"
                          onClick={() => setShowUpdates(!showUpdates)}
                        >
                          {showUpdates ? 'Hide' : 'Show'} Values
                        </span> 
                      )
                    }
                    {updates && showUpdates &&
                      updates.map((update, i) => (
                        <div key={i} >
                          <Label className="my-3" >
                            <span>Step {update.index}</span>
                            <Input 
                              className='rounded-md p-2 '
                              value={update.value}
                              placeholder={'enter some testing text...'}
                              onChange={e => updateChange(e, i)} 
                            />
                          </Label>
                        </div>
                      ))
                    }
                  </div>
                </div>

                <div className='mt-8' style={{ display: isTest }}>
                  <div className='flex justify-between mb-2'>
                    <label htmlFor="threshold-range" className="my-auto block mb-2 text-sm text-gray-800 dark:text-gray-400">At what score should the Test "Fail"?</label>
                    <span className='mr-2 text-lg font-bold text-gray-600 dark:text-gray-400 px-2 py-1'>
                      {threshold}%
                    </span>
                  </div>
                  <input 
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                    id="threshold-range" 
                    type="range" 
                    min="60" 
                    max="99" 
                    value={threshold}
                    onChange={(e) => setThreshold(e.target.value)}
                  >
                  </input>
                </div>

                <div style={{ display: showConfigs }} className='mb-6'>
                  <div className='mt-8'>
                    <span
                      className='text-sm underline text-blue-600 cursor-pointer w-auto'
                      onClick={() => setShowAdvanced(!showAdvanced)}
                    >
                      {showAdvanced ? 'Hide' : 'Show'} Advanced
                    </span>
                  </div>
                  {showAdvanced &&
                    <div className='mb-8'>
                      <div className='grid gap-6 grid-cols-2' >
                        <div className={`mb-4 mt-6 ${task_type === 'report' ? 'hidden' : 'block'}`}>
                          <Label className="mb-2">Browser</Label>
                          <Select className="rounded-md p-2 mt-1"
                            value={browser}
                            onChange={(e) => setBrowser(e.target.value)}
                          >
                            <option value="chrome">Chrome</option>
                            <option value="firefox">Firefox</option>
                            <option value="edge">Edge</option>
                          </Select>
                        </div>
                        <div className='mb-4 mt-6'>
                          <Label className="mb-2">Device</Label>
                          <Label radio>
                            <Input 
                              type="radio" 
                              value="desktop" 
                              checked={device === 'desktop' ? true : false} 
                              onChange={(e) => {
                                setDevice(e.target.value)
                              }}
                            />
                            <span className="ml-2">Desktop</span>
                          </Label>
                          <Label className="ml-6" radio>
                            <Input 
                              type="radio" 
                              value="mobile" 
                              checked={device === 'mobile' ? true : false} 
                              onChange={(e) => {
                                setDevice(e.target.value)
                              }}
                            />
                            <span className="ml-2">Mobile</span>
                          </Label>
                        </div>
                      </div>

                      <div className='grid gap-6 mb-8 grid-cols-2 ' >
                        <div className='grid grid-cols-2 gap-3'>

                          <Label className="mt-4">
                            <span>Interval (sec)</span>
                            <Input 
                              className='rounded-md p-2 max-w-[6rem]'
                              onChange={e => setInterval(e.target.value)} 
                              value={interval}
                              type='number'  
                              placeholder={5}/>
                          </Label>

                          <Label className="mt-4">
                            <span>Timeout (sec)</span>
                            <Input 
                              className='rounded-md p-2 max-w-[6rem]'
                              onChange={e => setTimeout(e.target.value)} 
                              value={timeout}
                              type='number'  
                              placeholder={300}/>
                          </Label>

                          </div>
                          <div className='grid grid-cols-2 gap-3'>

                            <Label className="mt-4">
                              <span>Min Wait (sec)</span>
                              <Input
                                className='rounded-md p-2 max-w-[6rem]' 
                                onChange={e => setMinTime(e.target.value)} 
                                value={min_wait_time}
                                type='number'  
                                placeholder={10}/>
                            </Label>

                            <Label className="mt-4">
                            <span>Max Wait (sec)</span>
                              <Input 
                                className='rounded-md p-2 max-w-[6rem]'
                                onChange={e => setMaxTime(e.target.value)} 
                                value={max_wait_time}
                                type='number'  
                                placeholder={60}/>
                            </Label>

                          </div>

                          <Label className="mt-4">
                            <span>Window Size</span>
                            <Input
                              className='rounded-md p-2' 
                              onChange={e => setWindowSize(e.target.value)} 
                              value={window_size} 
                              placeholder={'1920,1080'}/>
                          </Label>

                          <div>
                            <div className="mt-6">
                              <div className='flex justify-end mt-2'>
                                <span className='mr-4 text-sm text-gray-800 dark:text-gray-400'>Disable Animations</span>
                                <div>
                                  <Toggle id='scan-disable-animations' small checked={disableAnimations} onChange={() => setDisableAnimation(!disableAnimations)} />
                                </div>
                              </div>
                            </div>
                            <div className="mt-2">
                                <div className='flex justify-end mt-2'>
                                  <span className='mr-4 text-sm text-gray-800 dark:text-gray-400'>Auto Height</span>
                                  <div>
                                    <Toggle id='scan-auto-height' small checked={autoHeight} onChange={() => setAutoHeight(!autoHeight)} />
                                  </div>
                                </div>
                            </div>
                            {(task_type === 'test' || task_type === 'testcase') && 
                              <div className="mt-2">
                                  <div className='flex justify-end mt-2'>
                                    <span className='mr-4 text-sm text-gray-800 dark:text-gray-400'>Create Issue</span>
                                    <div>
                                      <Toggle id='test-create-issue' small checked={createIssue} onChange={() => setCreateIssue(!createIssue)} />
                                    </div>
                                  </div>
                              </div>
                            }
                          </div>
                          
                      </div>

                      <div style={{ display: (task_type === 'scan' || task_type === 'test') ? 'block' : 'none'}}>
                        <Label className="mt-2 mb-6">
                          <span>Element ID's to Mask (seperated by commas)</span>
                          <Textarea 
                            className='rounded-md p-2 '
                            onChange={e => setMaskIds(e.target.value)} 
                            value={mask_ids}
                            type='text'  
                            rows='2'
                            placeholder={'example-id-1, example-id-2'}/>
                        </Label>
                      </div>

                      <div className='h-[1px] w-full bg-blue-400'>
                      </div>

                    </div>
                    
                  }

                </div>
                
              </div>
            
            </div>

            <div className='grid gap-6 mb-8 grid-cols-2 ' >
              <div>

                <Label className="mt-4">
                  <span>Timezone</span>
                  <Select className="rounded-md p-2 mt-1" value={timezone} onChange={e => setTimezone(e.target.value)}>
                    <Timezones/>
                  </Select>
                </Label>

                <Label className="mt-4">
                  <span>Date</span>
                  <div className='mt-1'>
                    <Datepicker 
                      options={dateOptions}
                      show={showDatePicker}
                      setShow={() => setShowDatePicker(!showDatePicker)}
                      // selected={begin_date} 
                      onChange={(date) => setBeginDate(date)} 
                      // customInput={<Input className='rounded-md p-2 w-58'/>}
                    />
                  </div>
                </Label>

              </div>
              <div>

                <Label className="mt-4">
                  <span>Frequency</span>
                  <Select className="rounded-md p-2 mt-1" value={freq} onChange={e => setFreq(e.target.value)}>
                    <option value="daily">daily</option>
                    <option value="weekly">weekly</option>
                    <option value="monthly">monthly</option>
                  </Select>
                </Label>

                <Label className="mt-4">
                  <span>Time</span>
                  <Select className="rounded-md p-2 mt-1" value={time} onChange={e => setTime(e.target.value)}>
                    <Times/>
                  </Select>
                </Label>
              </div>
            </div>

          </div>
          </ModalBody>

          <ModalFooter>
            <div className="hidden sm:block">
              <Button layout="outline" onClick={handleAddSchedule} disabled={task_type === '' ? true : isLoading}>
              <Spinner display={isLoading}/>
                {props.schedule ? 'Update' : 'Submit'}
              </Button>
            </div>
            <div className="block w-full sm:hidden">
              <Button block size="large" layout="outline" onClick={handleAddSchedule} disabled={task_type === '' ? true : isLoading}>
              <Spinner display={isLoading}/>
                {props.schedule ? 'Update' : 'Submit'}
              </Button>
            </div>
          </ModalFooter>
        </div>
      </Modal>
    </>
  )
}

export default ScheduleModal


