import { createRoot } from 'react-dom/client';
import React, { Suspense } from 'react'
import './assets/css/index.css'
import App from './App'
import { SidebarProvider } from './context/SidebarContext'
import ThemedSuspense from './components/ThemedSuspense'
import { Windmill } from '@windmill/react-ui'
import { Toaster } from 'react-hot-toast'
import myTheme from './assets/themes/myTheme.js'
import * as serviceWorker from './serviceWorker'



const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <SidebarProvider>
    <Suspense fallback={<ThemedSuspense />}>
      <Windmill usePreferences theme={myTheme}>
      <App/>
      <Toaster/>
      </Windmill >
    </Suspense>
  </SidebarProvider>,
);




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
