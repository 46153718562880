import React, {useState} from 'react';

// eslint-disable-next-line
import { Button } from '@windmill/react-ui';
import DeleteSiteModal from "../Modals/DeleteSiteModal"
import PageModal from '../Modals/PageModal';
import CrawlSiteModal from '../Modals/CrawlSiteModal';
import TestcaseModal from '../Modals/TestcaseModal';
import ScheduleModal from '../Modals/ScheduleModal';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import Dropdown from './Dropdown/Dropdown';




function SiteActionsButton(props){

    const [isMenuOpen, setIsMenuOpen] = useState(false)

    function openModal() {
        setIsMenuOpen(true);
    }
    
    function closeModal() {
        setIsMenuOpen(false)
    }

    return (
        <div className="text-center relative">
                    
            <Button
                layout="outline"
                size="regular"
                className="text-gray-600 dark:text-gray-400"
                onClick={(e) => {
                    e.nativeEvent.stopImmediatePropagation()
                    if(isMenuOpen){
                        closeModal();
                    }else{
                        openModal();
                    }
                }}
            >
                <div className='flex justify-evenly'>
                    <div className='mr-2'>
                        <p>Actions</p>
                    </div>
                    <div>
                        {isMenuOpen ?
                            <ChevronUpIcon className="w-5 h-5 " aria-hidden="true"/>
                        :
                            <ChevronDownIcon className="w-5 h-5 " aria-hidden="true"/>
                        }
                    </div>
                </div>
                
            </Button>
            <Dropdown
                classes={'-ml-28'}
                isOpen={isMenuOpen}
                onClose={() => {
                    closeModal()
                }}
            >
                <PageModal site={props.site} callBack={props.callBack} type='dropdown'/>
                <CrawlSiteModal site={props.site} callBack={props.callBack} type='dropdown'/>
                <ScheduleModal scope={'site'} object={props.site} callBack={props.callBack} type='dropdown'/>
                <TestcaseModal site={props.site} callBack={props.callBack} type='dropdown'/>
                <DeleteSiteModal site={props.site} type='dropdown'/>
                
            </Dropdown>

        </div>
    )
}


export default SiteActionsButton