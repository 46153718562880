import React, {useEffect, useState} from 'react'
import { Card, CardBody, Badge} from '@windmill/react-ui';
import Gear from '../Buttons/Gear';




function StepCard (props) {

    const [step, setStep] = useState(props.step)
    const [stepIndex, setStepIndex] = useState(props.stepIndex)


    useEffect(() => {
      setStep(props.step)
      // eslint-disable-next-line
    }, [props?.step])

    useEffect(() => {
        setStepIndex(props.stepIndex)
        // eslint-disable-next-line
    }, [props?.stepIndex])



    const getStepStatus = (step) => {
        var status = 'queued'
        var type = 'neutral'
        if(step.time_created !== null){
            status = 'working'
            type = 'primary'
        }
        if (step.passed === false){
            status = 'failed'
            type = 'danger'
        }
        if (step.passed === true){
            status = 'passed'
            type = 'success'
        }

        return <Badge type={type}>{status} {status === 'working' && <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/>}</Badge>
      }


    const getDate = (timestamp) => {
        let formatedDateTime = '-'
        if (timestamp !== null){
            let date = new Date(timestamp).toLocaleDateString();
            let time = new Date(timestamp).toLocaleTimeString('en-US');
            if (date === 'Invalid Date'){
                date = timestamp.substring(0, 10)
                time = timestamp.substring(11, 19) 
              }
            formatedDateTime = `${date} ${time}`
        }
        return formatedDateTime
    }

    const getDurration = (start, end) => {
        let startTime = new Date(start)
        let endTime = new Date(end)
        let durration = Math.round((endTime.getTime() - startTime.getTime()) / 1000)
        return end ? durration : 0
    }

    if (!step){return null}

    return (

        <div className='sticky top-2 max-h-[80vh] overflow-auto min-w-0 rounded-lg shadow-xs bg-white dark:bg-gray-800 p-4'>
            <h1 className='text-lg pl-4 pt-4 font-bold dark:text-gray-300'>Step #{stepIndex + 1}</h1>
            <div className='my-4 rounded px-2 py-2 bg-gray-100 dark:bg-gray-700 w-full' >
                <div className='px-6'>
                    <div className='flex justify-between'>
                        <h3 className='mt-2 font-semibold text-[1rem] mb-2 dark:text-gray-300 my-auto underline decoration-blue-600'>
                            Action
                        </h3>
                        <div className='mt-2'>
                            {getStepStatus(step.action)}
                        </div>
                    </div>
                    <div className='ml-2 bg-white dark:bg-gray-800 p-2 rounded mt-2'>
                        <div className='mb-1 text-sm font-semibold dark:text-gray-300'>
                            <p>type: <span className='ml-[2.67rem] text-xs text-gray-600 dark:text-gray-100 font-normal'>"{step.action.type}"</span></p>
                        </div>
                        <div className='my-1 text-sm font-semibold dark:text-gray-300'>
                            <p>durration: <span className='ml-3 text-xs text-gray-600 dark:text-gray-100 font-normal'>{getDurration(step.action.time_created, step.action.time_completed)} s</span></p>
                        </div>
                        <div className='my-1 text-sm font-semibold dark:text-gray-300'>
                            {step.action.path !== '' && <p>path: <span className='ml-11 text-xs text-gray-600 dark:text-gray-100 font-normal'>"{step.action.path}"</span></p>}
                        </div>
                        <div className='my-1 text-sm font-semibold dark:text-gray-300'>
                            {step.action.key !== '' && <p>key: <span className='ml-12 text-xs text-gray-600 dark:text-gray-100 font-normal'>"{step.action.key}"</span></p>}
                        </div>
                        <div className='my-1 text-sm font-semibold dark:text-gray-300'>
                            {step.action.type === 'change' && <p>value: <span className='ml-[2.3rem] text-xs text-gray-600 dark:text-gray-100 font-normal'>{step.action.value}</span></p>}
                        </div>
                        
                        { (step.action.type === 'click' || step.action.type === 'change') &&
                            <div className='overflow-auto whitespace-pre-wrap'>
                                <div className='flex justify-start'>
                                    <span className='mr-2 text-sm font-semibold dark:text-gray-300'>selector: </span>
                                    <pre className=''>
                                        <code className="ml-4 text-gray-600 dark:text-gray-100 text-[.7rem]">
                                            {step.action.element.selector ? step.action.element.selector : JSON.stringify(step.action.element)}
                                        </code>
                                    </pre>
                                </div>
                                <div className='flex justify-start'>
                                    <span className='mr-2 text-sm font-semibold dark:text-gray-300'>xpath: </span>
                                    <pre>
                                        <code className="ml-8 text-gray-600 dark:text-gray-100 text-[.7rem]">
                                            {step.action.element.xpath ? step.action.element.xpath : JSON.stringify(step.action.element)}
                                        </code>
                                    </pre>
                                </div>
                            </div>
                        }
                    </div>
                    

                    <div className='mt-6 text-sm mb-2 dark:text-gray-300 my-auto ml-2'>
                        {step.action.exception && 
                            <div className='overflow-auto whitespace-pre-wrap pb-6'> 
                                <p className='underline decoration-blue-600 mb-1 font-semibold'>Exception:</p>
                                <div className='overflow-auto whitespace-pre-wrap p-1 rounded bg-gray-800 ml-2'>
                                    <pre>
                                        <code className="text-gray-100 text-[.7rem]">
                                            {step.action.exception}
                                        </code>
                                    </pre>
                                </div>
                            
                            </div>
                        }
                        {step.action.image &&

                            <div className='my-4 pb-6'> 
                                <a href={step.action.image} target="_blank" className='cursor-pointer'>
                                    <img src={step.action.image} className='h-auto'/>
                                </a>
                            </div>

                        }
                    </div>

                </div>
            </div>
            {step.assertion.type !== '' &&
                <div className='my-4 rounded-r-md px-2 py-2 bg-gray-100 dark:bg-gray-700 w-full'>
                    <div className='px-6'>
                    <div className='flex justify-between'>
                        <h3 className='mt-2 font-semibold text-[1rem] mb-2 dark:text-gray-300 my-auto underline decoration-blue-600'>
                            Assertion
                        </h3>
                        <div className='mt-2'>
                            {getStepStatus(step.action)}
                        </div>
                    </div>
                    <div className='ml-2 bg-white dark:bg-gray-800 p-2 rounded mt-2'>
                        <div className='mb-1 text-sm font-semibold dark:text-gray-300'>
                            <p>type: <span className='ml-[2.67rem] text-xs text-gray-600 dark:text-gray-100 font-normal'>"{step.assertion.type}"</span></p>
                        </div>
                        <div className='my-1 text-sm font-semibold dark:text-gray-300'>
                            <p>durration: <span className='ml-3 text-xs text-gray-600 dark:text-gray-100 font-normal'>{getDurration(step.assertion.time_created, step.assertion.time_completed)} s</span></p>
                        </div>
                        <div className='my-1 text-sm font-semibold dark:text-gray-300'>
                            {step.assertion.value !== '' && <p>value: <span className='ml-[2.3rem] text-xs text-gray-600 dark:text-gray-100 font-normal'>{step.assertion.value}</span></p>}
                        </div>
                        <div className='overflow-auto whitespace-pre-wrap'>
                            <div className='flex justify-start'>
                                <span className='mr-2 text-sm font-semibold dark:text-gray-300'>selector: </span>
                                <pre className=''>
                                    <code className="ml-4 text-gray-600 dark:text-gray-100 text-[.7rem]">
                                        {step.assertion.element.selector ? step.assertion.element.selector : JSON.stringify(step.assertion.element)}
                                    </code>
                                </pre>
                            </div>
                            <div className='flex justify-start'>
                                <span className='mr-2 text-sm font-semibold dark:text-gray-300'>xpath: </span>
                                <pre>
                                    <code className="ml-8 text-gray-600 dark:text-gray-100 text-[.7rem]">
                                        {step.assertion.element.xpath ? step.assertion.element.xpath : JSON.stringify(step.assertion.element)}
                                    </code>
                                </pre>
                            </div>
                        </div>
                    </div>
                    

                    <div className='mt-6 text-sm mb-2 dark:text-gray-300 my-auto ml-2'>
                        {step.assertion.exception && 
                            <div className='overflow-auto whitespace-pre-wrap pb-6'> 
                                <p className='underline decoration-blue-600 mb-1 font-semibold'>Exception:</p>
                                <div className='overflow-auto whitespace-pre-wrap p-1 rounded bg-gray-800 ml-2'>
                                    <pre>
                                        <code className="text-gray-100 text-[.7rem]">
                                            {step.assertion.exception}
                                        </code>
                                    </pre>
                                </div>
                            
                            </div>
                        }
                        {step.assertion.image &&

                            <div className='my-4 pb-6'> 
                                <a href={step.assertion.image} target="_blank" className='cursor-pointer'>
                                    <img src={step.assertion.image} className='h-auto'/>
                                </a>
                            </div>

                        }
                    </div>

                </div>
                </div>
            }
        </div>
    )
}


export default StepCard