import React, { useState, useEffect } from 'react';
import { 
  Input, Button, Select, Label
} from '@windmill/react-ui';
import axios from "axios";
import toast from 'react-hot-toast';
import { TrashIcon, PlusIcon } from '../../icons';
import { DocumentTextIcon, DotsVerticalIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { SelectorIcon, XIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom'
import $ from 'jquery'
import Loader from '../Buttons/Loader';
import Tooltip from '../Tooltip/Tooltip';
import CopyCaseModal from '../Modals/CopyCaseModal';
import TestcaseModal2 from '../Modals/TestcaseModal2';




function CaseCard(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/case'
  const siteUrl = BACKEND_URL + '/v1/ops/site'
  const searchUrl = `${BACKEND_URL}/v1/ops/search`

  const emptyStep = {
    action: {
      type: "", 
      element: {
        selector: "",
        xpath: ""
      },
      path: "",
      value: "",
      key: ""
    }, 
    assertion: {
      type: "",
      element:  {
        selector: "",
        xpath: ""
      },
      value: "",
    }, 
  }

  const case_id = props.case ? props.case.id : ''
  const [name, setName] = useState(props.case ? props.case.name : '');
  const [steps, setSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [displayImages, setDisplayImages] = useState(false);
  const [results, setResults] = useState([])
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(false)
  const [site, setSite] = useState(null)
  const [dragStart, setDragStart] = useState(null)
  const [dragEnd, setDragEnd] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {
    getData(props);
    // eslint-disable-next-line
  },[props?.case])


  const getData = async (props) => {
    if (props.case){
      setName(props.case.name);
      if (props.case.site){
        await axios.get(`${siteUrl}/${props.case.site}`)
          .then((r) => {
            setSite({
              str: r.data.site_url,
              id: r.data.id,
              type: 'site'
            })
          }).catch((e)=>{
            console.log(e)
          })
      }
      await fetch(props.case.steps.url)
        .then(r => r.json())
        .then((out) => {
          setSteps(out)
          setLoading(false)
        }).catch((e)=>{
          console.log(e)
          setLoading(false)
        })
    }
  }


  // search for site 
  const search = async (q) => {
    if (q.length > 0){
      await axios.get(`${searchUrl}?query=site:${q}`)
          .then((r) => {
              setResults(r.data)
          })
          .catch((e) => {
              console.log(e)
          })
    }
    
  }


  // handle input change
  const handleStepChange = (e, index) => {
    let { name, value } = e.target;
    let obj = e.target.getAttribute('data-obj')
    let sub = e.target.getAttribute('data-sub-obj')
    let list = [...steps];
    
    if (sub){
      list[index][obj][name][sub] = value;
    } else {
      list[index][obj][name] = value;
    }

    setSteps(list);
  };

  // remove full step
  const handleRemoveStep = (index) => {
    let list = [...steps];
    list.splice(index, 1);
    setSteps(list);
  };

  // remove assertion
  const handleAssertRemove = (index) => {
    let blankAssertion = {
      type: "",
      element: {
        selector: "",
        xpath: ""
      },
      value: "",
    }
    let list = [...steps];
    list[index]['assertion'] = blankAssertion;
    setSteps(list);
  };


  // handle click event of the Add button
  const handleAddStep = (index=null) => {
    var newSteps = []
    if (index===null){
      newSteps = [...steps, emptyStep]
    }else {
      newSteps = [
        ...steps.slice(0, index),
        emptyStep,
        ...steps.slice(index)
      ];
    }
    setSteps(newSteps);
  };


  // toggle display
  const displayItem = (id, display=null) => {
    if (display !== null){
      if (display === false){
        $("#"+id).hide()
      }else if(display === true){
        $("#"+id).show()
      }
    }else {
      if($("#"+id).is(":visible")){
        $("#"+id).hide()
      }else{
          $("#"+id).show()
      }
    }
  }


  // toggle display for all images
  const toggleImages = (display) => {
    for (var i in steps){
      if (steps[i]['action']['img'] !== null){
        displayItem(`img-${i}`, display)
      }
    }
  }


  // handle reordring of steps
  const handleDrop = (endIndex) => {
    let start = dragStart
    let end = endIndex

    // copy current steps
    let newSteps = [...steps]
  
    // copy moving step
    let movingStep = newSteps[start]

    // remove moving step from current positon
    newSteps.splice(start, 1);

    // insert step in new index location
    let updateSteps = [
      ...newSteps.slice(0, end),
      movingStep,
      ...newSteps.slice(end)
    ];
    
    // update state
    setSteps(updateSteps)
  }



  // update case details
  const handleUpdateCase = () => {

    if (name === ''){
      toast.error('Case needs a name')
      return
    }

    if (!site){
      toast.error('Case needs a Site')
      return
    }

    let data = {
      "case_id": case_id,
      "site_id": site.id,
      "site_url": site.str,
      "name": name,
      "steps": steps,
    }

    setIsLoading(true);
  
    axios.post(`${url}`, data)
    .then((res) => {
      if (res.data) {
        setIsLoading(false);
        toast.success(props.case ? 'Case updated!' : 'Case created!');
        if (!props.case){
          navigate(`/case/${res.data.id}`)
        }
        if (props.callBack){
          props.callBack();
        }
      }
    })
    .catch((e) => {
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    })
  }



  if (loading) {
    return <Loader item={'Case Steps'}/>
  }
  

  return (

    <div className='min-w-0 bg-gray-100 dark:bg-gray-900 relative pb-4 mb-32'>
      <div className='z-10 sticky top-0 bg-gray-100 dark:bg-gray-900 w-full h-4'></div>
      <div className='z-30 sticky top-2 bg-white dark:bg-gray-800 pt-4 px-4 rounded-t-lg'>
        <div className="flex justify-between mt-3 mb-3">
          <div className='flex justify-start'>
            <Label className="mb-3 mr-8">
                <span>Case Name:</span>
              <Input 
                className='rounded-md p-2'
                placeholder="Nickname you'll remember" 
                value={name} 
                style={{ width: '15rem',}}
                onChange={e => {setName(e.target.value)}}
              />
            </Label>
            <div>
              <div className='flex justify-start'>
                <span className={`mt-6 text-xs font-semibold py-1 px-2 rounded-xl ${props.case.type === 'generated' ? 'text-gray-700 bg-gray-100 dark:text-gray-100 dark:bg-gray-700' : 'bg-blue-100 dark:bg-blue-600 text-blue-600 dark:text-blue-100'} mx-2`}>
                  {String(props.case.type).toUpperCase()}
                </span>
              </div>
            </div>
          </div>

          {!site &&
            <div>
              <div className='w-full flex justify-center relative'>

                <Label className="mb-3 mx-8">
                  <span>Site:</span>
                  <input 
                    className='block text-sm focus:outline-none dark:text-gray-300 form-input leading-5 focus:border-blue-400 dark:border-gray-600 focus:shadow-outline-blue dark:focus:border-gray-600 dark:focus:shadow-outline-gray dark:bg-gray-700 rounded-md p-2 w-[20rem]'
                    placeholder='search for a site'
                    value={query}
                    onFocus={() => {
                      if (!open){
                          setOpen(true)
                      }
                    }}
                    onChange={(e) => {
                        setQuery(e.target.value === '/' ? '' : e.target.value)
                        if (e.target.value !== '') {
                            setOpen(true)
                            search(e.target.value);
                        } else {
                            setOpen(false)
                        }
                    }}
                  />
              </Label>

              </div>

              {query.length >= 1 && open &&
                  <div className={`md:w-1/3 w-1/2 absolute z-30 mx-auto shadow rounded-b-lg p-4 bg-white dark:bg-gray-800 dark:text-gray-300 text-gray-600 overflow-x-scroll`}>

                      { results && 

                          results.slice().map((resource, i) => (
                            <div key={i}>
                              {resource.type !== 'case' && resource.type !== 'issue' && resource.type !== 'page' &&
                                <div 
                                    className="flex justify-start px-2 py-2 dark:hover:bg-gray-700 hover:bg-gray-100 my-2 rounded-md cursor-pointer" 
                                    onClick={() => {
                                        setSite({
                                          'str': resource.str,
                                          'id': resource.id,
                                          'type': resource.type
                                        })
                                        setQuery('')
                                        setOpen(false)
                                    }}
                                >   
                                    <div className='mr-3 my-auto'>
                                        <div className={`text-center px-2 py-1 rounded-full text-[8px] dark:bg-blue-600 bg-blue-100`}>
                                            <span className={`font-bold dark:text-white text-blue-700`}>
                                              SITE
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <h2 className="font-semibold text-sm">
                                          {resource.str}
                                        </h2>
                                    </div>
                                </div> 
                              }
                            </div>
                          ))

                      }

                  </div>
              }
            </div>             
          }

          {site && 
            
            <label className='mb-3'>
            <span className='text-sm text-gray-700 dark:text-gray-400 '>Site:</span>
            <div className='flex justify-between px-2 py-2 dark:bg-gray-700 bg-gray-100 rounded-xl my-auto'>
              <div className='mr-2 my-auto'>
                <div className="flex justify-start cursor-auto">
                  <div className='mr-3 my-auto'>
                    <div className={`text-center px-2 py-1 rounded-full text-[8px] dark:bg-blue-600 bg-blue-100`}>
                      <span className={`font-bold dark:text-white text-blue-700`}>
                          SITE
                      </span>
                    </div>
                  </div>
                  <div>
                    <h2 className="font-semibold text-sm text-gray-700 dark:text-gray-300 max-w-[12rem] truncate">
                      {site.str}
                    </h2>
                  </div>
                </div>
              </div>
              <div className=''>
                <div onClick={() => setSite(null)} className='cursor-pointer text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-6 h-6 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white my-auto'>
                  <XIcon className="w-3 h-3" aria-hidden="true" />
                </div>
              </div>
            </div>
            </label>

          
          }
            
          <div className='flex justify-end'>
            <div className="mt-5 mx-1">
              <Tooltip content='save case'>
                <Button onClick={handleUpdateCase} layout="outline" size="small" disabled={isLoading} aria-label="Save Case" className=''>
                  <DocumentTextIcon className="w-5 h-5" aria-hidden="true"/>
                </Button>
              </Tooltip>
            </div>
            <div className="mt-5 mx-1">
              <Tooltip content='toggle images'>
                <Button 
                  onClick={() => {
                    toggleImages(!displayImages)
                    setDisplayImages(!displayImages)
                  }} 
                  layout="outline" 
                  size="small" 
                  aria-label="toggle images" 
                  className=''
                >
                  {!displayImages ? <EyeIcon className="w-5 h-5" aria-hidden="true"/> : <EyeOffIcon className="w-5 h-5" aria-hidden="true"/>}
                </Button>
              </Tooltip>
            </div>
            <div className="mt-5 mx-1">
              <Tooltip content='copy case'>
                <CopyCaseModal case={props.case} type='icon-btn'/>
              </Tooltip>
            </div>
            <div className="mt-5 mx-1">
              <Tooltip content='run testcase'>
                <TestcaseModal2 case={props.case} type='icon-btn'/>
              </Tooltip>
            </div>
          </div>
        </div>
        <hr/>
      </div>
      
    
      <ul className="pt-4 mb-8 overflow-auto steps px-4 rounded-b-lg shadow-xs bg-white dark:bg-gray-800" id="steps-container"> 
      
      {steps.map((x, i) => {
          return (

            <li 
              className="step" 
              key={i} 
              draggable={true} 
              id={i} 
              onDragStart={() => setDragStart(i)}
              onDragOver={(e) => e.preventDefault()}
              onDrop={() => handleDrop(i)}
            >
              <div className="my-6 py-3 px-4 rounded bg-gray-100 dark:bg-gray-700">
                <div className='flex justify-between'>
                  <div className='flex justify-start p-1'>
                    <h1 className="text-gray-600 dark:text-gray-300 mb-2 mr-4 font-semibold" style={{ fontSize: '1.1em',}}>Step {i+1}</h1>
                    <div className="mb-2" style={{ width: '5rem',}}>
                      {steps.length !== 1 &&  <Button onClick={() => handleRemoveStep(i)} className='mr-1' layout="link" size="icon" aria-label="Remove"><TrashIcon className="w-5 h-5" aria-hidden="true" /></Button>}
                      <Button onClick={() => handleAddStep(i)} layout="link" size="icon" aria-label="Add"><PlusIcon className="w-5 h-5" aria-hidden="true" /></Button>
                    </div>
                  </div>
                  <div className='justify-self-end cursor-move'>
                    <DotsVerticalIcon className='h-5 text-gray-600 dark:text-gray-300' aria-label="Reorder Step"/>
                  </div>
                </div>
                
                <div className='flex gap-2' draggable={false}>
                  
                  <div className='my-1'>
                    <label>
                      <span className='text-gray-600 dark:text-gray-300 text-[.6rem] ml-1'>Action Type:</span>
                      <Select className="rounded-md p-2 dark:bg-gray-800/60" 
                        style={{ width: '8rem', maxHeight: '3em'}}
                        name="type"
                        value={x.action.type}
                        data-obj='action'
                        onChange={(e) => {handleStepChange(e, i)}}
                      >
                        <option value="">–––</option>
                        <option value="navigate">navigate</option>
                        <option value="click">click</option>
                        <option value="change">change</option>
                        <option value="keyDown">key down</option>
                        <option value="scroll">scroll</option>
                      </Select>
                    </label>
                  </div>
                  
                  {(x.action.type === 'click' || x.action.type === 'change') && 
                    <div className='my-1'>
                      <label>
                        <span className='text-gray-600 dark:text-gray-300 text-[.6rem] ml-1'>Selector:</span>
                        <Input
                          className="rounded-md p-2 dark:bg-gray-800/60"
                          style={{ width: '30rem', display: (x.action.type === 'click' || x.action.type === 'change') ? 'block' : 'none',}}
                          placeholder='#content > main > footer > div.flex.flex-col.items-center'
                          name="element"
                          value={x.action.element.selector}
                          data-obj='action'
                          data-sub-obj='selector'
                          onChange={e => handleStepChange(e, i)}
                          draggable={true} 
                          onDragStart={
                            (e) => e.preventDefault()
                          }
                        />
                      </label>
                      <label className=''>
                        <span className='text-gray-600 dark:text-gray-300 text-[.6rem] ml-1'>xPath:</span>
                        <Input
                          className="rounded-md p-2 dark:bg-gray-800/60"
                          style={{ width: '30rem', display: (x.action.type === 'click' || x.action.type === 'change') ? 'block' : 'none',}}
                          placeholder='//*[@id="content"]/main/footer/div[2]/div/nav/a[7]'
                          name="element"
                          value={x.action.element.xpath}
                          data-obj='action'
                          data-sub-obj='xpath'
                          onChange={e => handleStepChange(e, i)}
                          draggable={true} 
                          onDragStart={
                            (e) => e.preventDefault()
                          }
                        />
                      </label>
                    </div>
                  }

                  { x.action.type === 'navigate' &&
                    <div className='my-1' draggable={false}>
                      <label>
                        <span className='text-gray-600 dark:text-gray-300 text-[.6rem] ml-1'>Path:</span>
                        <Input
                          className="rounded-md p-2 dark:bg-gray-800/60"
                          style={{ width: '25rem',  display: x.action.type === 'navigate' ? 'block' : 'none',}}
                          placeholder='/blog'
                          name="path"
                          value={x.action.path}
                          data-obj='action'
                          onChange={e => handleStepChange(e, i)}
                          draggable={true} 
                          onDragStart={
                            (e) => e.preventDefault()
                          }
                        />
                      </label>
                    </div>
                  }
                  
                  { (x.action.type === 'change' || x.action.type === 'scroll') &&
                    <div className='my-1'  draggable={false}>
                      <label>
                        <span className='text-gray-600 dark:text-gray-300 text-[.6rem] ml-1'>Value:</span>
                        <Input
                          className="rounded-md p-2 dark:bg-gray-800/60"
                          style={{ width: '25rem', display: (x.action.type === 'change' || x.action.type === 'scroll') ? 'block' : 'none',}} 
                          placeholder='some testing text or x,y scroll coordinates'
                          name="value"
                          value={x.action.value}
                          data-obj='action'
                          onChange={e => handleStepChange(e, i)}
                        />
                      </label>
                    </div>
                  }


                  { (x.action.type === 'keyDown') &&
                    <div className='my-1'  draggable={false}>
                      <label>
                        <span className='text-gray-600 dark:text-gray-300 text-[.6rem] ml-1'>Value:</span>
                        <Input
                          className="rounded-md p-2 dark:bg-gray-800/60"
                          style={{ width: '8rem', display: x.action.type === 'keyDown' ? 'block' : 'none',}} 
                          placeholder='Enter'
                          name="key"
                          value={x.action.key}
                          data-obj='action'
                          onChange={e => handleStepChange(e, i)}
                          draggable={true} 
                          onDragStart={
                            (e) => e.preventDefault()
                          }
                        />
                      </label>
                    </div>
                  }


                </div>

                
              
                <div className={`gap-2 flex mt-1`} id={`assertion-${i}`} style={{ display: x.assertion.type === '' ? 'none' : 'flex', }}  draggable={false}>
                  
                  <div className='my-1'>
                    <label>
                      <span className='text-gray-600 dark:text-gray-300 text-[.6rem] ml-1'>Assertion Type:</span>                    
                      <Select className=" rounded-md p-2 dark:bg-gray-800/60" 
                        style={{ width: '8rem', maxHeight: '3em'}}
                        name="type"
                        value={x.assertion.type}
                        data-obj='assertion'
                        onChange={e => handleStepChange(e, i)}
                      >
                        <option value="">–––</option>
                        <option value="exists">exists</option>
                        <option value="match">match</option>
                      </Select>
                    </label>
                  </div>

                  <div className='my-1'>
                    <label>
                      <span className='text-gray-600 dark:text-gray-300 text-[.6rem] ml-1'>Selector:</span>
                      <Input
                        className="rounded-md p-2 dark:bg-gray-800/60"
                        style={{ width: '30rem', }}
                        placeholder='#content > main > footer > div.flex.flex-col.items-center'
                        name="element"
                        value={x.assertion.element.selector}
                        data-obj='assertion'
                        data-sub-obj='selector'
                        onChange={e => handleStepChange(e, i)}
                        draggable={true} 
                        onDragStart={
                          (e) => e.preventDefault()
                        }
                      />
                    </label>
                    <label className=''>
                      <span className='text-gray-600 dark:text-gray-300 text-[.6rem] ml-1'>xPath:</span>
                      <Input
                        className="rounded-md p-2 dark:bg-gray-800/60"
                        style={{ width: '30rem', }}
                        placeholder='//*[@id="content"]/main/footer/div[2]/div/nav/a[7]'
                        name="element"
                        value={x.assertion.element.xpath}
                        data-obj='assertion'
                        data-sub-obj='xpath'
                        onChange={e => handleStepChange(e, i)}
                        draggable={true} 
                        onDragStart={
                          (e) => e.preventDefault()
                        }
                      />
                    </label>
                  </div>
                
                  {x.assertion.type === 'match' &&
                    <div className='my-1'>
                      <label>
                        <span className='text-gray-600 dark:text-gray-300 text-[.6rem] ml-1'>Value:</span>
                        <Input
                          className="rounded-md p-2 dark:bg-gray-800/60"
                          style={{ width: '25rem', display: x.assertion.type === 'match' ? 'block' : 'none', }} 
                          placeholder='this is some testing text...'
                          name="value"
                          value={x.assertion.value}
                          data-obj='assertion'
                          onChange={e => handleStepChange(e, i)}
                          draggable={true} 
                          onDragStart={
                            (e) => e.preventDefault()
                          }
                        />
                      </label>
                    </div>
                  }

                  <div className='mt-8'>
                    <Button 
                      id={`assert-delete-btn-${i}`}
                      onClick={() => {
                        displayItem(`assertion-${i}`)
                        displayItem(`assert-btn-${i}`)
                        handleAssertRemove(i)
                        }}
                      layout="link" 
                      size="icon" 
                      aria-label="Remove"
                    >
                      <TrashIcon className="w-5 h-5" aria-hidden="true" />
                    </Button>
                  </div>

                </div>

                
                
                {/* toggle display of element image */}
                
                  <div className='flex gap-6 mt-3'>
                    <div 
                      className={`${x.action.type === '' ? 'hidden' : (x.assertion.type === '' ? 'block' : 'hidden')}`} 
                      id={`assert-btn-${i}`} layout='primary' 
                    >
                      <button 
                        className={`flex text-[12px] px-2 py-1 dark:text-blue-100 text-gray-700 ring-gray-700 dark:ring-gray-400 rounded-lg ring-1 bg-blue-100 dark:bg-blue-600 ml-1`}
                        onClick={() => {
                          displayItem(`assertion-${i}`)
                          displayItem(`assert-btn-${i}`)
                          }}
                        >
                        Assertion
                        <PlusIcon className='h-3 w-3 my-auto ml-1'/>
                      </button>
                    </div>

                    {x.action.img &&
                      <div>
                        <button className='text-[12px] px-2 py-1 dark:text-gray-400 text-gray-700 ring-gray-700 dark:ring-gray-400 rounded-lg ring-1 mr-2 ml-1' onClick={() => displayItem(`img-${i}`)}>
                          Toggle Image
                        </button>
                      </div>
                    }
                    {x.action.img &&
                      <div>
                        <img id={`img-${i}`} className='max-h-32 max-w-lg' src={`data:image/png;base64,${x.action.img}`} style={{display: 'none'}}/>
                      </div>
                    }

                  </div>
              </div>
            </li>
            
          );
        })}
        <li onClick={() => handleAddStep(null)} className='max-w-lg cursor-pointer flex justify-center my-20 p-1 px-4 rounded dark:bg-gray-700 bg-gray-50 hover:shadow hover:-translate-y-[2px] duration-200 ease-in-out border-dashed border-4 dark:border-gray-600 border-gray-400'>
          <p className='dark:text-gray-400 text-sm text-gray-500 font-semibold'>Add New Step</p>
        </li>
      </ul>
    </div>

  )
}

export default CaseCard


