import {useState, useEffect} from 'react'
import PageTitle from '../components/Typography/PageTitle'
import CaseCard from '../components/Cards/CaseCard';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Loader from '../components/Buttons/Loader';
import BackButton from '../components/Buttons/BackButton';
import DeleteCaseModal from '../components/Modals/DeleteCaseModal';
import Gear from '../components/Buttons/Gear';
import toast from 'react-hot-toast';




function CaseDetail() {
    
    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const processUrl = `${BACKEND_URL}/v1/ops/process`;
    const casesUrl = `${BACKEND_URL}/v1/ops/case`;
    const siteUrl = `${BACKEND_URL}/v1/ops/site`;
    
    const [thiscase, setThisCase] = useState(null);
    const [site, setSite] = useState(null);
    const [processes, setProcesses] = useState([])
    const [proc, setProc] = useState([])

    const { id } = useParams();
    const navigate = useNavigate();


    const loadData = async () => {
        // get case info
        if (id){
            await axios.get(`${casesUrl}/${id}`)
            .then((r) => {
                setThisCase(r.data)
                if (r.data.site){
                    axios.get(`${siteUrl}/${r.data.site}`)
                        .then((r) => {
                            setSite(r.data)
                        })
                        .catch((err) => {
                            console.log(err)
                
                        });
                } else {
                    setSite(false)
                }
                
            })
            .catch((err) => {
                console.log(err)
    
            });
        }
        
    }

    let prcs = []
    let old = []

    const getProcesses = async () => {
        await axios.get(`${processUrl}?type=case.pre_run&object_id=${id}`)
            .then((r) => {
                old = processes
                prcs = []
                var results = r.data.results

                for (var i in results){
                    if (!results[i]['success']){
                        prcs = [...prcs, results[i]]
                    }
                    else if (results[i]['success']){
                        for (var n in old){
                            if (results[i]['id'] === old[n]['id']){
                                toast.success('Pre-Run Complete')
                                setProc(old[n])
                            }
                        }
                    }
                }
                setProcesses(prcs)
            }).catch((e) => {
                console.log(e)
            })
    }


    useEffect(() => {
        const interval = setInterval(() => {
            console.log('triggered interval')
            getProcesses();
        }, 5000);
        return () => {
          clearInterval(interval);
        };
        // eslint-disable-next-line
    }, [processes]);


    useEffect(() => {
        loadData();
        getProcesses();
        // eslint-disable-next-line 
    },[])


    const urlLength = () => {
        var text_size = 'text-2xl'
        var len = site ? site.site_url.length : ''
        if (len >= 50 ){
            var text_size = 'text-xl'
        }else if (len > 36 && len < 50){
            var text_size = 'text-xl'
        }else if (len < 50){
            var text_size = 'text-2xl'
        }
        return text_size
    }
    
    const navBack = () => {
        window.location.href = '/case'
    }


    if (thiscase === null || site === null) {
        return <Loader item={'Case Data'}/>
    }


    return (
        <>
            <div className='flex justify-between'>
                <PageTitle>
                    <div className="flex mb-2">
                        <div>
                            <div className='flex justify-start '>
                                <div>
                                    <span className='text-center px-2 py-1 rounded-full text-[11px] font-semibold bg-gray-300 dark:bg-gray-500 dark:text-white text-gray-700'>
                                        CASE
                                    </span>
                                </div>
                                <h1 className='font-semibold text-gray-400 dark:text-gray-500 text-[.9rem] ml-3 mt-1'>
                                    {new Date(thiscase.time_created).toLocaleDateString()}
                                </h1>
                            </div>
                            <h3 onClick={() => navigate(`/site/${site.id}`)} className={`hover:underline cursor-pointer max-w-lg ${urlLength()}`}>{site ? site.site_url : ''}</h3>
                            <div className='flex justify-start mt-2'>
                                <div className='md:hidden block -mt-[.3rem]'>
                                    <DeleteCaseModal type='icon' case={thiscase} callBack={navBack}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageTitle> 
                <div className='mt-8 md:block hidden ml-1'>
                    <DeleteCaseModal type='button' callBack={navBack} case={thiscase}/>
                </div>
                
            </div>
            
            <BackButton />

            {/* process display */}
            {processes && processes.length >= 1 &&
                <div className='my-4 rounded-lg bg-white dark:bg-gray-800 max-w-3xl py-1 px-8 max-h-[10rem] overflow-scroll'>
                    {processes.map((process, i) => (
                        <div key={i} className='my-6'>
                            <div className='flex justify-start gap-6'>
                                <div className='flex justify-start gap-2'><Gear size='h-5 w-5'/> <p className='text-gray-600 dark:text-gray-400 text-sm pb-2'>Processing Case</p></div>
                                <div><pre className='text-gray-600 dark:text-gray-400 text-[10px] pt-[.15rem]'><code>process_id: {process.id}</code></pre></div>
                            </div>
                            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                <div className="bg-blue-600 h-2.5 rounded-full transition-all ease-out duration-1000" style={{width: `${process.progress}%`}}></div>
                            </div>
                        </div>
                    ))}
                </div>
            }
            
            <div className="mb-8">

                <CaseCard callBack={loadData} case={thiscase} site={site}/>
                
            </div>
        </>
    )
}

export default CaseDetail