import { useEffect, useState } from 'react'
import PageTitle from '../components/Typography/PageTitle'
import AccountSchedulesTable from '../components/Tables/AccountSchedulesTable';
import ScheduleSlideModal from '../components/Modals/ScheduleSlideModal'
import axios from 'axios';
import Loader from '../components/Buttons/Loader';




function Schedules() {

    const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
    const schedulesUrl = `${BACKEND_URL}/v1/ops/schedule?scope=account`;
    const [schedules, setSchedules] = useState(null)
    
    // get current issues count
    const getSchedules = async () => {
        setSchedules([])
        await axios.get(schedulesUrl)
            .then((r) => {
                setSchedules(r.data.results)
            })
            .catch((e) => {
                console.log(e)
            })
    }
    
    useEffect(() => {
        getSchedules();
    }, [])
    
    if (!schedules){
        return <Loader item={'Schedules'}/>
    }
    
    return (
        <>
            <div className='md:flex md:justify-between mb-6'>
                <div>
                    <PageTitle>Schedules</PageTitle>
                    <p className='text-gray-600 dark:text-gray-400 max-w-sm'>
                        Where all your account-level Schedules & Automations live. 
                        
                    </p>
                </div>
                
                
                <div className='mt-8 md:ml-8'>
                    <ScheduleSlideModal
                        scope={'account'}
                        type={'account-button'}
                        callBack={getSchedules}
                    />
                </div>

            </div>
       

            <div className='mt-6'>
                <AccountSchedulesTable scope={'account'} schedules={schedules} callBack={getSchedules}/>
            </div>    


        </>
    )
}

export default Schedules