import React, { useState } from 'react';
import { TrashIcon } from '../../icons';
import axios from "axios";
import toast from 'react-hot-toast';
import { Button } from '@windmill/react-ui';
import Modal from './Modal/Modal'
import ModalBody from './Modal/ModalBody'
import ModalFooter from './Modal/ModalFooter'
import DropdownItem from '../Buttons/Dropdown/DropdownItem'
import Spinner from '../Buttons/Spinner'


function DeleteScheduleModal(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/schedule/'

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  const handleDelete = (evt) => {
    evt.preventDefault();
    setIsLoading(true);

    if (props.ids) {
      axios.post(`${BACKEND_URL}/v1/ops/schedules/delete`, {'ids': props.ids})
        .then(() => {
          refreshData();
        }).catch((e) => {
          if (e.response) {
            toast.error(e.response.data.reason)
            setIsLoading(false);
          }
        });

    }else{
      axios.delete(`${url}${props.schedule.id}`)
        .then(() => {
          refreshData();
        }).catch((e) => {
          if (e.response) {
            toast.error(e.response.data.reason)
            setIsLoading(false);
          }
        });
    }
  }

  const refreshData = () => {
    setIsLoading(false);
    setIsModalOpen(false);
    props.callBack && props.callBack();
    toast.success(`Schedule${props.ids ? 's' : ''} deleted`);
  }


  const ButtonType = () => {
    if(props.type==='icon'){
      return(
        <div>
          <Button onClick={openModal} layout="link" size="icon" aria-label="Delete">
            <TrashIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
        </div>
        
      )
    }else if(props.type==='button'){
      return (
        <Button layout="outline" iconRight={TrashIcon} onClick={openModal}>
            <span>Delete Schedule</span>
        </Button>
      )
    } else if(props.type==='dropdown'){
      return (
        <DropdownItem tag="a" onClick={openModal} className="z-40 relative">
          <TrashIcon className="w-4 h-4 mr-3 my-auto" aria-hidden="true" />
          <span>Delete Schedule</span>
        </DropdownItem>
      )
    }
  }



  return (
    <>

      <div>{ButtonType()}</div>

      <Modal isOpen={isModalOpen} onClose={closeModal} title={'Delete Site'}>
        <ModalBody>

          {(props.schedule || props.ids.length === 1) ? 
            <p>Are you sure you want to permanently delete this Schedule from your account?</p>
          :
            <p>Are you sure you want to permanently delete {props.ids.length} Schedules from your account?</p>
          }

        </ModalBody>
        <ModalFooter>

          <div className="hidden sm:block">
            <Button layout="outline" onClick={handleDelete} disabled={isLoading}>
              <Spinner display={isLoading}/>
              Confirm Delete
            </Button>
          </div>
          <div className="block w-full sm:hidden">
            <Button block size="large" layout="outline" onClick={handleDelete} disabled={isLoading}>
            <Spinner display={isLoading}/>
              Confirm Delete
            </Button>
          </div>
        
        </ModalFooter>
      </Modal>
    </>
  )
}

export default DeleteScheduleModal
