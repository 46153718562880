import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import SectionTitle from '../Typography/SectionTitle'
import {Table, TableHeader, TableCell, TableBody, TableRow, TableFooter,
  TableContainer, Badge, Pagination, Input} from '@windmill/react-ui'
import DeletePageModal from '../Modals/DeletePageModal'
import axios from 'axios'
import ScanModal from '../Modals/ScanModal';
import TestModal from '../Modals/TestModal';
import Tooltip from '../Tooltip/Tooltip';
import Gear from '../Buttons/Gear';
import Loader from '../Buttons/Loader';






function PageTable(props) {

  const [pageIds, setPageIds] = useState([]);
  const [allPages, setAllPages] = useState(false)
  const [collecting, setCollecting] = useState(false);
  const [pages, setPages] = useState(null)
  const [offSet, setOffSet] = useState(0)

  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const getPagesUrl = `${BACKEND_URL}/v1/ops/page?site_id=${props.site.id}&limit=10&offset=${offSet}`;

  const getData = async () => {
    await axios.get(getPagesUrl).then((r) => {
      setPages(r.data)
    });
  }
  
  useEffect(() => {
    getData()
    // eslint-disable-next-line 
  }, [props?.pages,])

  // setup pages control for table
  const pageTable = 1

  // pagination setup
  const resultsPerPage = 10

  // pagination change control
  const onPageChangeTable = async (p) => {
    if(p){
      setOffSet(((p-1)*10))
      let _offset = (p-1)*10
      let _getPagesUrl = `${BACKEND_URL}/v1/ops/page?site_id=${props.site.id}&limit=10&offset=${_offset}`;
      await axios.get(_getPagesUrl)
        .then((r) => {
          setPages(r.data)
      }).catch(err => console.log(err))
    }
  }


  const handleCheckBox = (id) => {
    setAllPages(false)
    if (pageIds.includes(id)){
      let ids = [...pageIds]
      let newIds = ids.filter(i => i !== id)
      setPageIds([...newIds])
    }else{
      setPageIds([...pageIds, id])
    }
  }


  const handleSelectAll = async () => {
    if (pageIds.length === 0){
      // get all pages in account
      let offSet = 0
      let allIds = []
      let next = true
      setCollecting(true)

      while (next){
        let paginatedUrl = `${BACKEND_URL}/v1/ops/page?site_id=${props.site.id}&limit=100&offset=${offSet}`
        await axios.get(paginatedUrl)
          .then((r) => {
            for (let i in r.data.results){
              allIds.push(r.data.results[i].id)
            }
            if (r.data.next){
              offSet = offSet + 100
            }else{next = false}
        }).catch(err => console.log(err))
      }
      
      setPageIds([...allIds])
      setAllPages(true)
      setCollecting(false)

    }else{
      setPageIds([])
      setAllPages(false)
    }
  }

  const deleteCallBack = async () => {
    await getData()
    setPageIds([])
    setAllPages(false)
  }



  function getHealthStatus(page) {
    let scan = page.info.latest_scan
    let badge = 'neutral';
    let health = 'no data';
    let value = health;
    let score = scan.score
    
    if (score !== 0 && score !== null){
      score = score < 100 ? Number(score.toFixed(1)) : score
      if (score >= 75) {
        health = 'Good';
        badge = 'success';
      } else if (score >= 60 && score < 75) {
        health = 'Okay';
        badge = 'warning';
      } else {
        health = 'Poor';
        badge = 'danger';
      }
      value = health+' - '+score+'%'
    }

    if (scan.time_completed === null){
      value = 'working'
      badge = 'neutral'
    }
    
    return (
      <Badge type={badge}>
        {value} 
        { value === 'working' && 
          <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/>
        }
      </Badge> 
    )
  }




  function getDate(timestamp) {
    if(timestamp){
      let date = new Date(timestamp).toLocaleDateString()
      if (date === 'Invalid Date'){
        date = timestamp.substring(0, 10)
      }
      return date;
    }else{
      return '-';
    }
  }

  function getTestScore(page) {
    let test = page.info.latest_test
    let badge = 'neutral';
    let health = 'no data';
    let value = health;
    let date = getDate(test.time_created)
    if(test.score!==null){
        let score = test.score < 100 ? Number(test.score.toFixed(2)) : test.score
        if (test.status === 'passed'){
          health = 'Passed'
          badge = 'success'
        } else if (test.status === 'failed') {
          health = 'Failed'
          badge = 'danger'
        }
        // value = health+' - '+score+'%';
        value = health;

    }
    if (test.id){
      if (test.time_completed === null){
        value = 'working'
        badge = 'neutral'
      }
    }
    return (
      <div className='flex justify-start'>
        <div className='mr-3'>
          <Badge type={badge}>
            {value} 
            {value === 'working' && 
              <Gear size={'ml-2 h-4 w-4 mt-[.1rem]'} color={'dark:text-gray-200 text-gray-600'}/>
            }
          </Badge>
        </div>
        <div className='text-sm my-auto'>
            <span>{date}</span>
        </div>
      </div>
    )
  }


  const getCrawlStatus = () => {
    let start = props.site.time_crawl_started
    let end = props.site.time_crawl_completed
    let working = (
      <div className='flex justify-start my-auto'>
        <div className='mr-2'><Badge type='neutral'>working</Badge></div>
        <div className=''><Gear/></div>
      </div>
    )
    if (!end || !start){
      return working
    } else{return null}
  }

  if (pages===null){
    return <Loader item={'Pages'}/>
  }


  return (
    <>
      {pageIds.length > 0 &&
        <div className='flex justify-start'>
          <div className='mr-4'>
            <DeletePageModal ids={pageIds} type='button' callBack={deleteCallBack}/>
          </div>
          <div className='mr-4'>
            <ScanModal pageIds={pageIds} callBack={getData} type='button'/>
          </div>
          <div className='mr-4'>
            <TestModal pageIds={pageIds} callBack={getData} type='button'/>
          </div>
        </div>
      }
      <SectionTitle></SectionTitle>
      <TableContainer className="mb-8 dark:bg-gray-800 bg-white">
        <div className='flex justify-start'>
          <h1 className="text-gray-600 dark:text-gray-300" style={{ margin: '.7em', fontSize: '1.3em', fontWeight: 600, }}>Pages</h1>
          {getCrawlStatus()}
        </div>
        <Table>
          <TableHeader>
            <tr className='dark:bg-gray-800 bg-white'>
              <TableCell>
                <Input 
                  className='rounded-sm'
                  type="checkbox"
                  value={allPages} 
                  checked={allPages}
                  onChange={handleSelectAll}
                  disabled={collecting}
                />
              </TableCell>
              <TableCell>Url</TableCell>
              <TableCell>Health</TableCell>
              <TableCell>Last Scan</TableCell>
              <TableCell>Last Test</TableCell>
              <TableCell>Actions</TableCell>
            </tr>
          </TableHeader>
          <TableBody>
            {pages.results.slice((pageTable - 1) * resultsPerPage, pageTable * resultsPerPage).map((page, i) => (
              <TableRow key={i}>
                <TableCell>
                  <div>
                      <Input 
                        className='rounded-sm'
                        type="checkbox"
                        value={pageIds.includes(page.id)} 
                        checked={pageIds.includes(page.id)}
                        onChange={() => handleCheckBox(page.id)}
                        disabled={collecting}
                      />
                    </div>
                </TableCell>
                <TableCell>
                    <div style={{ maxWidth: '30rem' }} className='overflow-x-auto'>
                        <Link to={`/page/${page.id}`}><p className="font-semibold">{page.page_url}</p></Link>
                    </div>
                </TableCell>
                <TableCell>
                  {getHealthStatus(page)}
                </TableCell>
                <TableCell>
                  <span className="text-sm">{getDate(page.info.latest_scan.time_created)}</span>
                </TableCell>
                <TableCell>
                  {getTestScore(page)}
                </TableCell>
                <TableCell>
                  <div className="flex items-center space-x-2">
                    <Tooltip content='scan page'><ScanModal page={page} callBack={getData} type='icon'/></Tooltip>
                    <Tooltip content='test page'><TestModal page={page} callBack={getData} type='icon'/></Tooltip>
                    <Tooltip content='delete page'><DeletePageModal page={page} callBack={getData} type='icon'/></Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={pages.count}
            resultsPerPage={resultsPerPage}
            onChange={onPageChangeTable}
            label="Table navigation"
          />
        </TableFooter>
      </TableContainer>
    </>
  )
}




export default PageTable
