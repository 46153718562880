import React, { useState, useEffect } from 'react';
import {Table, TableHeader, TableCell, TableBody, TableRow, TableFooter,
  TableContainer, Pagination, Badge, Input} from '@windmill/react-ui';
import axios from 'axios';
import ScheduleSlideModal from '../Modals/ScheduleSlideModal.js';
import DeleteScheduleModal from '../Modals/DeleteScheduleModal.js';
import AutomationModal from '../Modals/AutomationModal.js';
import RunScheduleModal from '../Modals/RunScheduleModal.js';
import UpdateScheduleModal from '../Modals/UpdateScheduleModal.js'
import Tooltip from '../Tooltip/Tooltip.js';
import Loader from '../Buttons/Loader.js';






function AccountSchedulesTable(props) {


  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const emptySchedules = {results:[], count:0}
  const [schedules, setSchedules] = useState(emptySchedules);
  const [loading, setLoading] = useState(true)
  const [scheduleIds, setScheduleIds] = useState([]);
  const [collecting, setCollecting] = useState(false);
  const [allSchedules, setAllSchedules] = useState(false)
  const getSchedulesUrl = props.url ? props.url : `${BACKEND_URL}/v1/ops/schedule?scope=${props.scope}`;


  const getData = async () => {
    setSchedules(emptySchedules)
    await axios.get(getSchedulesUrl)
      .then((r) => {
        setLoading(false)
        setSchedules(r.data)
      })
      .catch((e) => {
        setSchedules(emptySchedules)
        setLoading(false)
        console.log(e)
      })
  }

  // !!!!! VERY IMPORTANT - this subscribes the component to listen for prop changes from the parent !!!!!!
  useEffect(() => {
    getData();
    return () => {
      getData;
    }
    // eslint-disable-next-line 
  }, [props?.schedules])

  

  // setup pages control for table
  const pageTable = 1

  // pagination setup
  const resultsPerPage = 10

  // pagination change control
  const onPageChangeTable = (p) => {
    if(p){
      let offSet = (p-1)*10
      let paginatedUrl = `${getSchedulesUrl}&limit=10&offset=${offSet}&scope=${props.scope}`
      axios.get(paginatedUrl)
        .then((r) => {
          setSchedules(r.data);
      })
    }
    
  }

  const handleCheckBox = (id) => {
    setAllSchedules(false)
    if (scheduleIds.includes(id)){
      let ids = [...scheduleIds]
      let newIds = ids.filter(i => i !== id)
      setScheduleIds([...newIds])
    }else{
      setScheduleIds([...scheduleIds, id])
    }
  }


  const handleSelectAll = async () => {
    if (scheduleIds.length === 0){
      // get all sites in account
      let offSet = 0
      let allIds = []
      let next = true
      setCollecting(true)
      
      while (next){
        let paginatedUrl = `${BACKEND_URL}/v1/ops/schedule?scope=account&limit=100&offset=${offSet}`
        await axios.get(paginatedUrl)
          .then((r) => {
            for (let i in r.data.results){
              allIds.push(r.data.results[i].id)
            }
            if (r.data.next){
              offSet = offSet + 100
            }else{next = false}
        }).catch(err => console.log(err))
      }
      
      setScheduleIds([...allIds])
      setAllSchedules(true)
      setCollecting(false)

    }else{
      deleteCallBack();
    }
  }


  const deleteCallBack = () => {
    setScheduleIds([])
    setAllSchedules(false)
    getData();
  }

  const getDateTime = (timestamp) => {
    if(timestamp){
      let date = new Date(timestamp).toLocaleDateString();
      let time = new Date(timestamp).toLocaleTimeString()
      let formatedDate = `${date} ${time}`
      return formatedDate
    }else{
        return '––';
    }
  }


  if (loading){
    return <Loader item={'Schedules'}/>
  }


  return (
    <>
      {scheduleIds.length > 0 &&
        <div className='flex justify-start mb-6'>
          <div className='mr-4'>
            <DeleteScheduleModal ids={scheduleIds} type='button' callBack={deleteCallBack}/>
          </div>
          <div className='mr-4'>
            <UpdateScheduleModal ids={scheduleIds} type='button' callBack={deleteCallBack} markAs='Active'/>
          </div>
          <div className=''>
            <UpdateScheduleModal ids={scheduleIds} type='button' callBack={deleteCallBack} markAs='Paused'/>
          </div>
        </div>
      }
      <TableContainer className="dark:bg-gray-800 bg-white">
        <div className='flex justify-between'>
          <h1 className="text-gray-600 dark:text-gray-300" style={{ margin: '.7em', fontSize: '1.3em', fontWeight: 600, }}>Schedules</h1>
        </div>
        <Table>
          <TableHeader>
            <tr className='dark:bg-gray-800 bg-white'>
              <TableCell>
                <Input 
                  className='rounded-sm'
                  type="checkbox"
                  value={allSchedules} 
                  checked={allSchedules}
                  onChange={handleSelectAll}
                  disabled={collecting}
                />
              </TableCell>
              <TableCell>Task</TableCell>
              <TableCell>Frequency</TableCell>
              <TableCell>Resources</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last Run</TableCell>
              <TableCell>Automation</TableCell>
              <TableCell>Actions</TableCell>
            </tr>
          </TableHeader>
          <TableBody>
            {schedules.results.slice((pageTable - 1) * resultsPerPage, pageTable * resultsPerPage).map((schedule, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <div>
                      <Input 
                        className='rounded-sm'
                        type="checkbox"
                        value={scheduleIds.includes(schedule.id)} 
                        checked={scheduleIds.includes(schedule.id)}
                        onChange={() => handleCheckBox(schedule.id)}
                        disabled={collecting}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className='cursor-pointer'>
                      <ScheduleSlideModal object={props.object} scope={props.scope} schedule={schedule} callBack={deleteCallBack} type='text' text={schedule.task_type}/>
                    </div>
                  </TableCell>
                  <TableCell>
                    <pre className="text-sm"><code>{schedule.frequency} @{schedule.time}</code></pre>
                  </TableCell>
                  <TableCell>
                    <span className="text-sm">{schedule.resources.length === 0 ? 'ALL' : schedule.resources.length}</span>
                  </TableCell>
                  <TableCell>
                    <Badge type={schedule.status === 'Active' ? 'primary' : 'neutral'}>{schedule.status.toLowerCase()}</Badge>
                  </TableCell>
                  <TableCell>
                    <span className="text-sm">{getDateTime(schedule.time_last_run)}</span>
                  </TableCell>
                  <TableCell>
                    <AutomationModal schedule={schedule} site={props.site} page={props.page} callBack={deleteCallBack} type='button'/>
                  </TableCell>
                  <TableCell>
                    <div className="flex justify-start gap-2">
                      <Tooltip content='run schedule'><RunScheduleModal schedule={schedule} type='icon'/></Tooltip>
                      <Tooltip content={`mark ${schedule.status === 'Active' ? 'paused' : 'active'}`}>
                        <UpdateScheduleModal ids={[schedule.id]} type='icon' markAs={schedule.status === 'Active' ? 'Paused' : 'Active'} callBack={deleteCallBack}/>
                      </Tooltip>
                      <Tooltip content='delete schedule'><DeleteScheduleModal schedule={schedule} callBack={deleteCallBack} type='icon'/></Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
        <TableFooter>
          <Pagination
            totalResults={schedules.count}
            resultsPerPage={resultsPerPage}
            onChange={onPageChangeTable}
            label="Table navigation"
          />
        </TableFooter>
      </TableContainer>
    </>
  )
}




export default AccountSchedulesTable

