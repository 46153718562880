import React, { useState, useEffect } from 'react';
import { Button, Select, Label, Textarea } from '@windmill/react-ui';
import axios from "axios";
import toast from 'react-hot-toast';
import { DocumentTextIcon, PencilAltIcon } from '@heroicons/react/solid';
import { XIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom'
import Loader from '../Buttons/Loader';
import Tooltip from '../Tooltip/Tooltip';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
// eslint-disable-next-line
import rehypeHighlight from 'rehype-highlight'






function IssueCard(props) {
  const BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
  const url = BACKEND_URL + '/v1/ops/issue'
  const searchUrl = `${BACKEND_URL}/v1/ops/search`

  const issue_id = props.issue ? props.issue.id : null
  const [title, setTitle] = useState(props.issue ? props.issue.title : '');
  const [details, setDetails] = useState(props.issue ? props.issue.details : '');
  const [status, setStatus] = useState(props.issue ? props.issue.status : '');
  const [affected, setAffected] = useState(props.issue ? props.issue.affected : null);
  const [editing, setEditing] = useState(props.issue ? false : true);
  const [numRows, setNumRows] = useState(30)
  const [results, setResults] = useState([])
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(false)
  const [preview, setPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()


  const getPreview = () => {
    setPreview(true)
  }

  const getCompose = () => {
    setPreview(false)
  }


  useEffect(() => {
    if (props.issue){
      setTitle(props.issue.title);
      setDetails(props.issue.details);
      setStatus(props.issue.status);
      setAffected(props.issue.affected)
      var len = props.issue.details.split(/\r\n|\r|\n/).length
      if (len > 30){setNumRows(len + 8)}
    }
    setLoading(false)
    // eslint-disable-next-line
  },[props?.issue])



  const search = async (q) => {
    if (q.length > 0){
      await axios.get(`${searchUrl}?query=${q}`)
          .then((r) => {
              setResults(r.data)
          })
          .catch((e) => {
              console.log(e)
          })
    }
    
  }


  const handleIssueUpdate = (evt) => {
    evt.preventDefault();
    const data = {
      "id": issue_id,
      "title": title,
      "details": details,
      "status": status,
      "affected": affected,
    }

    if (title === ''){
      toast.error('Issue needs a title!')
      return
    }

    setIsLoading(true);
    

    axios.post(`${url}`, data)
    .then((r) => {
      if (r.data) {
        setTitle(r.data.title);
        setDetails(r.data.details);
        setStatus(r.data.status);
        setAffected(r.data.affected)
        props.callBack();
        setEditing(false)
        setIsLoading(false);
        toast.success(props.issue ? 'Issue updated!' : 'Issue created!');
        if (!props.issue){
          navigate(`/issue/${r.data.id}`)
        }
      }
    })
    .catch((e) => {
      if (e.response) {
        toast.error(e.response.data.reason)
        setIsLoading(false);
      }
    })
  }



  const formatMarkdown = () => {

      var details = document.getElementById('details')
      var h1s = details.getElementsByTagName('h1')
      var h2s = details.getElementsByTagName('h2')
      var h3s = details.getElementsByTagName('h3')
      var h4s = details.getElementsByTagName('h4')
      var as = details.getElementsByTagName('a')
      var bs = details.getElementsByTagName('strong')
      var ths = details.getElementsByTagName('th')
      var tables = details.getElementsByTagName('table')
      var codes = details.getElementsByTagName('code')

      for (var i in h1s) {
        try{
          if (h1s[i]){
            h1s[i].classList.add('dark:text-gray-200')
          }
        }catch {}
      }

      for (var i in h2s) {
        try{
          if (h2s[i]){
            h2s[i].classList.add('dark:text-gray-200')
          }
        }catch {}
      }

      for (var i in h3s) {
        try{
          if (h3s[i]){
            h3s[i].classList.add('dark:text-gray-200')
          }
        }catch {}
      }

      for (var i in h4s) {
        try{
          if (h4s[i]){
            h4s[i].classList.add('dark:text-gray-200')
          }
        }catch {}
      }

      for (var i in tables) {
        try{
          if (tables[i]){
            tables[i].classList.add('dark:text-gray-300')
            tables[i].classList.add('max-w-[25rem]')
            tables[i].classList.add('table-auto')
          }
        } catch {}
      }

      for (var i in ths) {
        try{
          if (ths[i]){
            ths[i].classList.add('dark:text-gray-300')
          }
        } catch {}
      }

      for (var i in as) {
        try{
          if (as[i]){
            as[i].classList.add('text-blue-600')
            as[i].classList.add('dark:text-blue-500')
            as[i].setAttribute('target', '_blank')
          }
        } catch {}
      }

      for (var i in bs) {
        try{
          if (bs[i]){
            bs[i].classList.add('dark:text-gray-300')
          }
        } catch {}
      }

      for (var i in codes) {
        try{
          if (codes[i]){
            codes[i].classList.add('text-gray-300')
            codes[i].classList.add('bg-gray-800')
            codes[i].classList.add('p-1')
            codes[i].classList.add('rounded')
          }
        } catch {}
      }
    
  }

  useEffect(() => {
    if ((editing && preview) || (!editing)){
      setTimeout(() => {
        try{
          formatMarkdown();
        } catch{}
      }, 100);
    }
    
    
  }, [props?.issue, formatMarkdown])



  if (loading) {
    return <Loader item={'Issue Data'}/>
  }



  return (

    <div className='min-w-0 bg-gray-100 dark:bg-gray-900 relative pb-4 mb-32'>
      <div className='z-10 sticky top-0 bg-gray-100 dark:bg-gray-900 w-full h-4'></div>
      
      <div className={`z-30 sticky top-2 bg-white dark:bg-gray-800 pt-4 px-4 rounded-t-lg`}>

        <div className={`flex justify-between mt-3 ${editing ? 'mb-0' : 'mb-6'}`}>
          <div className='flex justify-start'>
            
            {editing ? 
              <Label className="mb-3 mr-8">
                <span>Title:</span>
                <input 
                  className='block text-sm focus:outline-none dark:text-gray-300 form-input leading-5 focus:border-blue-400 dark:border-gray-600 focus:shadow-outline-blue dark:focus:border-gray-600 dark:focus:shadow-outline-gray dark:bg-gray-700 rounded-md p-2 w-[20rem]'
                  placeholder="Issue Title"
                  value={title} 
                  onChange={e => {setTitle(e.target.value)}}
                />
              </Label>
              :
              <h2 className='font-semibold text-xl text-gray-600 dark:text-gray-400'>{title}</h2>
            }
  
            <div>
              <div className='flex justify-start'>
                
                {editing ? 
                  <Label className="mb-3">
                    <span>Status:</span>
                    <Select className="rounded-md p-2" 
                      style={{ width: '8rem', maxHeight: '3em'}}
                      name="status"
                      value={status}
                      onChange={e => setStatus(e.target.value)}
                    >
                      <option value="open">open</option>
                      <option value="closed">closed</option>
                    </Select>
                  </Label>
                  :
                  <div className='my-auto ml-10'>
                    <span className={`mt-6 text-xs font-semibold py-1 px-2 rounded-xl ${status === 'open' ? 'bg-blue-100 dark:bg-blue-600 text-blue-600 dark:text-blue-100' : 'text-green-700 bg-green-100 dark:text-green-100 dark:bg-green-700'} mx-2`}>
                      {String(status).toUpperCase()}
                    </span>
                  </div>
                }
                
              </div>
            </div>
          </div>
              
          {editing && !affected &&
            <div>
              <div className='w-full flex justify-center relative'>

                <Label className="mb-3 mx-8">
                  <span>Affected:</span>
                  <input 
                    className='block text-sm focus:outline-none dark:text-gray-300 form-input leading-5 focus:border-blue-400 dark:border-gray-600 focus:shadow-outline-blue dark:focus:border-gray-600 dark:focus:shadow-outline-gray dark:bg-gray-700 rounded-md p-2 w-[20rem]'
                    placeholder='search for a page or site'
                    value={query}
                    onFocus={() => {
                      if (!open){
                          setOpen(true)
                      }
                    }}
                    onChange={(e) => {
                        setQuery(e.target.value === '/' ? '' : e.target.value)
                        if (e.target.value !== '') {
                            setOpen(true)
                            search(e.target.value);
                        } else {
                            setOpen(false)
                        }
                    }}
                  />
              </Label>

              </div>

              {query.length >= 1 && open &&
                  <div className={`md:w-1/3 w-1/2 absolute z-30 mx-auto shadow rounded-b-lg p-4 bg-white dark:bg-gray-800 dark:text-gray-300 text-gray-600 overflow-x-scroll`}>

                      { results && 

                          results.slice().map((resource, i) => (
                            <div key={i}>
                              {resource.type !== 'case' && resource.type !== 'issue' &&
                                <div 
                                    className="flex justify-start px-2 py-2 dark:hover:bg-gray-700 hover:bg-gray-100 my-2 rounded-md cursor-pointer" 
                                    onClick={() => {
                                        setAffected({
                                          'str': resource.str,
                                          'id': resource.id,
                                          'type': resource.type
                                        })
                                        setQuery('')
                                        setOpen(false)
                                    }}
                                >   
                                    <div className='mr-3 my-auto'>
                                        <div className={`text-center px-2 py-1 rounded-full text-[8px] ${resource.type === 'site' ? 'dark:bg-blue-600 bg-blue-100' : 'bg-gray-200 dark:bg-gray-600'}`}>
                                            <span className={`font-bold ${resource.type === 'site' ? 'dark:text-white text-blue-700' : 'dark:text-white text-gray-700'} `}>
                                                {resource.type.toUpperCase()}
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <h2 className="font-semibold text-sm">
                                            {resource.str}
                                        </h2>
                                    </div>
                                </div> 
                              }
                            </div>
                          ))

                      }

                  </div>
              }
            </div>             
          }

          {editing && affected && 
            
            <label className='mb-3'>
            <span className='text-sm text-gray-700 dark:text-gray-400 '>Affected:</span>
            <div className='flex justify-between px-2 py-2 dark:bg-gray-700 bg-gray-100 rounded-xl my-auto'>
              <div className='mr-2 my-auto'>
                <div className="flex justify-start  cursor-auto">   
                  <div className='mr-3 my-auto'>
                    <div className={`text-center px-2 py-1 rounded-full text-[8px] ${affected.type === 'site' ? 'dark:bg-blue-600 bg-blue-100' : 'bg-gray-200 dark:bg-gray-600'}`}>
                      <span className={`font-bold ${affected.type === 'site' ? 'dark:text-white text-blue-700' : 'dark:text-white text-gray-700'} `}>
                          {affected.type.toUpperCase()}
                      </span>
                    </div>
                  </div>
                  <div>
                    <h2 className="font-semibold text-sm text-gray-700 dark:text-gray-300 max-w-[12rem] truncate">
                      {affected.str}
                    </h2>
                  </div>
                </div>
              </div>
              <div className=''>
                <div onClick={() => setAffected(null)} className='cursor-pointer text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-6 h-6 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white my-auto'>
                  <XIcon className="w-3 h-3" aria-hidden="true" />
                </div>
              </div>
            </div>
            </label>

          
          }
            
          <div className='flex justify-end'>
            <div className="mx-2 my-auto">
              
              {editing ? 
                <Tooltip content='save issue'>
                  <Button onClick={handleIssueUpdate} layout="outline" size="small" disabled={isLoading} aria-label="Save Issue" className='px-1'>
                    <DocumentTextIcon className="w-5 h-5" aria-hidden="true"/>
                  </Button>
                </Tooltip>
                :
                <Tooltip content='edit issue'>
                  <Button onClick={() => setEditing(true)} layout="outline" size="small" disabled={isLoading} aria-label="Edit Issue" className='px-1'>
                    <PencilAltIcon className="w-5 h-5" aria-hidden="true"/>
                  </Button>
                </Tooltip>
              }
              
            </div>

    
          </div>
        </div>
        {/* preview / compose btns */}
        {editing && 
          <div className="flex justify-arround mb-2">
            <div className="m-2">
                <button
                  className={`flex text-[12px] px-2 py-1 rounded-lg ring-1
                    ${!preview && 'bg-blue-100 dark:bg-blue-600 dark:text-blue-100 text-gray-700 ring-gray-700 dark:ring-gray-400'} 
                    ${preview && 'dark:text-gray-400 text-gray-700 ring-gray-700 dark:ring-gray-400'}`
                  }
                  onClick={() => getCompose()}
                >
                  Compose
                </button>
            </div>
            <div className="m-2">
                <button
                  className={`flex text-[12px] px-2 py-1 rounded-lg ring-1 ml-1
                      ${preview && 'bg-blue-100 dark:bg-blue-600 dark:text-blue-100 text-gray-700 ring-gray-700 dark:ring-gray-400'} 
                      ${!preview && 'dark:text-gray-400 text-gray-700 ring-gray-700 dark:ring-gray-400'}`
                  }
                  onClick={() => {
                    getPreview();
                  }}
                >
                  Preview
                </button>
            </div>
          </div>
        }
        <hr/>
      </div>
      
    
      <div className={`pt-4 mb-8 overflow-auto px-4 rounded-b-lg shadow-xs bg-white dark:bg-gray-800`} id='details'> 

        {editing ? 

          <>
            {preview ?
              <div className='dark:text-gray-300 p-6'>
                <Markdown className='prose dark:text-gray-300 table-auto' remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]}>{details}</Markdown>
              </div>
              :
              <code className='mb-6'>
                <Textarea 
                  className="rounded-md p-2 whitespace-pre text-wrap" rows={numRows}
                  placeholder='### Supports **Markdown** ' 
                  name="datils"
                  value={details}
                  disabled={isLoading}
                  onChange={(e) => {
                    setDetails(e.target.value)
                    var len = e.target.value.split(/\r\n|\r|\n/).length
                    console.log(len)
                    if (len + 5 > 30){
                      setNumRows(len + 5)
                    }
                  }}
                />
              </code>
            }
          </>
          
          :

          <div className='dark:text-gray-300 p-6 mb-6'>
            <Markdown className='prose dark:text-gray-300 table-auto' remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeHighlight]}>{details}</Markdown>
          </div>
        
        }

      </div>

    </div>

  )
}

export default IssueCard


